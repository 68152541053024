/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CityPopulateResponseDTO } from './CityPopulateResponseDTO';
import type { VenueMinDataResponseDTO } from './VenueMinDataResponseDTO';

export type VenueListResponseDTO = {
    _id: string;
    name: string;
    /**
     * If type = travel then this value may not be.
     */
    address?: string;
    /**
     * If type = travel then this value may not be.
     */
    city?: CityPopulateResponseDTO;
    /**
     * If type = travel then this value may not be.
     */
    priceRating?: VenueListResponseDTO.priceRating;
    logo: string;
    photos: Array<string>;
    /**
     * If type = travel then this value may not be.
     */
    type?: string;
    experienceType: VenueListResponseDTO.experienceType;
    status: VenueListResponseDTO.status;
    likesCount: number;
    bookingCount: number;
    venueMinData: VenueMinDataResponseDTO;
    /**
     * Popularity status added by the admin
     */
    isPopular?: boolean;
    isLike: boolean;
    createdAt: string;
    updatedAt: string;
};

export namespace VenueListResponseDTO {

    /**
     * If type = travel then this value may not be.
     */
    export enum priceRating {
        LOW = 'low',
        MEDIUM = 'medium',
        HIGH = 'high',
        VERY_HIGH = 'veryHigh',
    }

    export enum experienceType {
        EVENT = 'event',
        TRAVEL = 'travel',
    }

    export enum status {
        PENDING = 'pending',
        ACTIVE = 'active',
        HIDDEN = 'hidden',
        DELETED = 'deleted',
    }


}
