/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CommonDataResponseDTO } from '../models/CommonDataResponseDTO';
import type { CreateCommonDataDTO } from '../models/CreateCommonDataDTO';
import type { PageCommonDataResponseDTO } from '../models/PageCommonDataResponseDTO';
import type { UpdateCommonDataDTO } from '../models/UpdateCommonDataDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class DiningStyleService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Dining Style creation
     * @returns any Dining Style successfully created
     * @throws ApiError
     */
    public createDiningStyle({
requestBody,
}: {
requestBody: CreateCommonDataDTO,
}): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/dining-style',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Wrong authorization data`,
                403: `Only an admin with the right isSuperAdmin can execute the request.`,
            },
        });
    }

    /**
     * Update dining style name
     * @returns void 
     * @throws ApiError
     */
    public updateDiningStyle({
id,
requestBody,
}: {
/**
 * Id
 */
id: string,
requestBody: UpdateCommonDataDTO,
}): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/dining-style/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Wrong authorization data`,
                403: `Only an admin with the right isSuperAdmin can execute the request.`,
                404: `Dining Style not found`,
            },
        });
    }

    /**
     * Delete dining style
     * @returns void 
     * @throws ApiError
     */
    public deleteDiningStyle({
id,
}: {
/**
 * Id
 */
id: string,
}): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/dining-style/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Wrong authorization data`,
                403: `Only an admin with the right isSuperAdmin can execute the request.`,
                404: `Dining style not found`,
            },
        });
    }

    /**
     * DiningStyle
     * @returns CommonDataResponseDTO DiningStyle
     * @throws ApiError
     */
    public getDiningStyle({
id,
}: {
/**
 * Id
 */
id: string,
}): CancelablePromise<CommonDataResponseDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/dining-style/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Wrong authorization data`,
                404: `DiningStyle not found`,
            },
        });
    }

    /**
     * Dining Styles list by venue
     * @returns PageCommonDataResponseDTO List of dining styles
     * @throws ApiError
     */
    public getDiningStylesListByVenue({
search,
status,
byCity,
byUserRole,
page = 1,
limit = 10,
isCount,
}: {
/**
 * Used to filter data by name
 */
search?: string,
/**
 * Can be used by the admin.
 */
status?: 'active' | 'hidden',
/**
 * Send the _id.
 */
byCity?: string,
/**
 * For list requests by user.
 */
byUserRole?: 'client' | 'talent' | 'admin',
page?: number,
/**
 * If 0 is specified, we will return the data without a limit
 */
limit?: number,
/**
 * If it actively returns the quantity.
 */
isCount?: boolean,
}): CancelablePromise<PageCommonDataResponseDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/dining-style/list-venue',
            query: {
                'search': search,
                'status': status,
                'byCity': byCity,
                'byUserRole': byUserRole,
                'page': page,
                'limit': limit,
                'isCount': isCount,
            },
            errors: {
                401: `Wrong authorization data`,
            },
        });
    }

    /**
     * Dining Styles list
     * @returns PageCommonDataResponseDTO List of dining styles
     * @throws ApiError
     */
    public getDiningStylesList({
search,
status,
byCity,
byUserRole,
page = 1,
limit = 10,
isCount,
}: {
/**
 * Used to filter data by name
 */
search?: string,
/**
 * Can be used by the admin.
 */
status?: 'active' | 'hidden',
/**
 * Send the _id.
 */
byCity?: string,
/**
 * For list requests by user.
 */
byUserRole?: 'client' | 'talent' | 'admin',
page?: number,
/**
 * If 0 is specified, we will return the data without a limit
 */
limit?: number,
/**
 * If it actively returns the quantity.
 */
isCount?: boolean,
}): CancelablePromise<PageCommonDataResponseDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/dining-style/list',
            query: {
                'search': search,
                'status': status,
                'byCity': byCity,
                'byUserRole': byUserRole,
                'page': page,
                'limit': limit,
                'isCount': isCount,
            },
            errors: {
                401: `Wrong authorization data`,
            },
        });
    }

    /**
     * DiningStyles Count
     * @returns number Dining Styles Count
     * @throws ApiError
     */
    public getDiningStylesCount({
search,
status,
byCity,
byUserRole,
}: {
/**
 * Used to filter data by name
 */
search?: string,
/**
 * Can be used by the admin.
 */
status?: 'active' | 'hidden',
/**
 * Send the _id.
 */
byCity?: string,
/**
 * For list requests by user.
 */
byUserRole?: 'client' | 'talent' | 'admin',
}): CancelablePromise<number> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/dining-style/count',
            query: {
                'search': search,
                'status': status,
                'byCity': byCity,
                'byUserRole': byUserRole,
            },
            errors: {
                401: `Wrong authorization data`,
            },
        });
    }

}
