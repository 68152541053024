/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CommonDataResponseDTO } from '../models/CommonDataResponseDTO';
import type { CreateCommonDataDTO } from '../models/CreateCommonDataDTO';
import type { PageCommonDataResponseDTO } from '../models/PageCommonDataResponseDTO';
import type { UpdateCommonDataDTO } from '../models/UpdateCommonDataDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class KitchenService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Kitchen creation
     * @returns any Kitchen successfully created
     * @throws ApiError
     */
    public createKitchen({
requestBody,
}: {
requestBody: CreateCommonDataDTO,
}): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/kitchen',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Wrong authorization data`,
                403: `Only an admin with the right isSuperAdmin can execute the request.`,
            },
        });
    }

    /**
     * Update kitchen name
     * @returns void 
     * @throws ApiError
     */
    public updateKitchen({
id,
requestBody,
}: {
/**
 * Id
 */
id: string,
requestBody: UpdateCommonDataDTO,
}): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/kitchen/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Wrong authorization data`,
                403: `Only an admin with the right isSuperAdmin can execute the request.`,
                404: `Kitchen not found`,
            },
        });
    }

    /**
     * Delete kitchen
     * @returns void 
     * @throws ApiError
     */
    public deleteKitchen({
id,
}: {
/**
 * Id
 */
id: string,
}): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/kitchen/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Wrong authorization data`,
                403: `Only an admin with the right isSuperAdmin can execute the request.`,
                404: `Kitchen not found`,
            },
        });
    }

    /**
     * Kitchen
     * @returns CommonDataResponseDTO Kitchen
     * @throws ApiError
     */
    public getKitchen({
id,
}: {
/**
 * Id
 */
id: string,
}): CancelablePromise<CommonDataResponseDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/kitchen/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Wrong authorization data`,
                404: `Kitchen not found`,
            },
        });
    }

    /**
     * Kitchens list by venue
     * @returns PageCommonDataResponseDTO List of kitchens
     * @throws ApiError
     */
    public getKitchensListByVenue({
search,
status,
byCity,
byUserRole,
page = 1,
limit = 10,
isCount,
}: {
/**
 * Used to filter data by name
 */
search?: string,
/**
 * Can be used by the admin.
 */
status?: 'active' | 'hidden',
/**
 * Send the _id.
 */
byCity?: string,
/**
 * For list requests by user.
 */
byUserRole?: 'client' | 'talent' | 'admin',
page?: number,
/**
 * If 0 is specified, we will return the data without a limit
 */
limit?: number,
/**
 * If it actively returns the quantity.
 */
isCount?: boolean,
}): CancelablePromise<PageCommonDataResponseDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/kitchen/list-venue',
            query: {
                'search': search,
                'status': status,
                'byCity': byCity,
                'byUserRole': byUserRole,
                'page': page,
                'limit': limit,
                'isCount': isCount,
            },
            errors: {
                401: `Wrong authorization data`,
            },
        });
    }

    /**
     * Kitchens list
     * @returns PageCommonDataResponseDTO List of kitchens
     * @throws ApiError
     */
    public getKitchensList({
search,
status,
byCity,
byUserRole,
page = 1,
limit = 10,
isCount,
}: {
/**
 * Used to filter data by name
 */
search?: string,
/**
 * Can be used by the admin.
 */
status?: 'active' | 'hidden',
/**
 * Send the _id.
 */
byCity?: string,
/**
 * For list requests by user.
 */
byUserRole?: 'client' | 'talent' | 'admin',
page?: number,
/**
 * If 0 is specified, we will return the data without a limit
 */
limit?: number,
/**
 * If it actively returns the quantity.
 */
isCount?: boolean,
}): CancelablePromise<PageCommonDataResponseDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/kitchen/list',
            query: {
                'search': search,
                'status': status,
                'byCity': byCity,
                'byUserRole': byUserRole,
                'page': page,
                'limit': limit,
                'isCount': isCount,
            },
            errors: {
                401: `Wrong authorization data`,
            },
        });
    }

    /**
     * Kitchens Count
     * @returns number Kitchen Count
     * @throws ApiError
     */
    public getKitchensCount({
search,
status,
byCity,
byUserRole,
}: {
/**
 * Used to filter data by name
 */
search?: string,
/**
 * Can be used by the admin.
 */
status?: 'active' | 'hidden',
/**
 * Send the _id.
 */
byCity?: string,
/**
 * For list requests by user.
 */
byUserRole?: 'client' | 'talent' | 'admin',
}): CancelablePromise<number> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/kitchen/count',
            query: {
                'search': search,
                'status': status,
                'byCity': byCity,
                'byUserRole': byUserRole,
            },
            errors: {
                401: `Wrong authorization data`,
            },
        });
    }

}
