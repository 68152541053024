/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UpdateBookingStatusDTO = {
    status: UpdateBookingStatusDTO.status;
    startDate?: string;
    endDate?: string;
};

export namespace UpdateBookingStatusDTO {

    export enum status {
        ACTIVE = 'active',
        CANCELED = 'canceled',
        DONE = 'done',
        PAID = 'paid',
    }


}
