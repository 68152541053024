/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CityPopulateResponseDTO } from './CityPopulateResponseDTO';

export type SystemMessageResponseDTO = {
    _id: string;
    userRole: SystemMessageResponseDTO.userRole;
    cities: Array<CityPopulateResponseDTO>;
    segmentation: SystemMessageResponseDTO.segmentation;
    title: string;
    text: string;
    status: SystemMessageResponseDTO.status;
    image: string;
    startDate: string;
    endDate: string;
    createdAt: string;
    updatedAt: string;
};

export namespace SystemMessageResponseDTO {

    export enum userRole {
        CLIENT = 'client',
        TALENT = 'talent',
        ADMIN = 'admin',
    }

    export enum segmentation {
        ALL = 'all',
        NEW = 'new',
        NOT_ACTIVE = 'notActive',
        NO_BOOKING = 'noBooking',
        ACTIVE = 'active',
        FREE = 'Free',
        ELITE = 'Elite',
        PREMIUM = 'Premium',
        UPDATED = 'updated',
        BOOSTED = 'boosted',
    }

    export enum status {
        ACTIVE = 'active',
        HIDDEN = 'hidden',
    }


}
