/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CityPopulateResponseDTO } from './CityPopulateResponseDTO';
import type { LuxuryServiceResponseDTO } from './LuxuryServiceResponseDTO';
import type { UserListResponseDTO } from './UserListResponseDTO';

export type LuxuryServiceRequestResponseDTO = {
    _id: string;
    /**
     * Detailed information is available for the admin
     */
    owner: (string | UserListResponseDTO);
    ownerCity: string;
    name: string;
    luxuryService: LuxuryServiceResponseDTO;
    city: CityPopulateResponseDTO;
    /**
     * There is if "luxuryService.category = restaurant"
     */
    restaurantName?: string;
    /**
     * There is if "luxuryService.category = villa"
     */
    destination?: string;
    /**
     * There is if "luxuryService.category = villa"
     */
    countRooms?: string;
    from?: string;
    to?: string;
    startDate: string;
    /**
     * There is if "luxuryService.category = villa,jets,yacht,cars"
     */
    endDate?: string;
    specialRequests: string;
    countUsers?: string;
    serviceLevel?: string;
    status: LuxuryServiceRequestResponseDTO.status;
    createdAt: string;
    updatedAt: string;
};

export namespace LuxuryServiceRequestResponseDTO {

    export enum status {
        ACTIVE = 'active',
        CANCELED = 'canceled',
        DONE = 'done',
    }


}
