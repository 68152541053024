/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UserMyProfileSubscriptionResponseDTO = {
    status: UserMyProfileSubscriptionResponseDTO.status;
    interval: UserMyProfileSubscriptionResponseDTO.interval;
    title: string;
    amount: number;
    /**
     * Date in unix format
     */
    periodStart: number;
    /**
     * Date in unix format
     */
    periodEnd: number;
    /**
     * Date in unix format
     */
    createdAt: number;
    /**
     * Date in unix format
     */
    cancelAt?: number;
    priceId?: string;
    isViewAdmin?: boolean;
};

export namespace UserMyProfileSubscriptionResponseDTO {

    export enum status {
        ACTIVE = 'active',
        CANCELED = 'canceled',
        INCOMPLETE = 'incomplete',
        INCOMPLETE_EXPIRED = 'incomplete_expired',
        PAST_DUE = 'past_due',
        PAUSED = 'paused',
        TRIALING = 'trialing',
        UNPAID = 'unpaid',
    }

    export enum interval {
        DAY = 'day',
        WEEK = 'week',
        MONTH = 'month',
        YEAR = 'year',
    }


}
