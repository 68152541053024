/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { UserListResponseDTO } from './UserListResponseDTO';

export type BookingUserResponseDTO = {
    /**
     * When Booking by id is received, there will be an object with data instead of a string.
     */
    user: (string | UserListResponseDTO);
    status: BookingUserResponseDTO.status;
    fee: number;
    rating?: number;
};

export namespace BookingUserResponseDTO {

    export enum status {
        PENDING = 'pending',
        WAITING_PAYMENT = 'waitingPayment',
        APPROVED = 'approved',
        DECLINED = 'declined',
        CHECK_IN = 'checkIn',
        INCOMPLETE = 'incomplete',
        CANCELED = 'canceled',
        DONE = 'done',
        PAID = 'paid',
    }


}
