/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateSystemMessageDTO } from '../models/CreateSystemMessageDTO';
import type { PageSystemMessageListResponseDTO } from '../models/PageSystemMessageListResponseDTO';
import type { SystemMessageResponseDTO } from '../models/SystemMessageResponseDTO';
import type { UpdateSystemMessageDTO } from '../models/UpdateSystemMessageDTO';
import type { UserSystemMessageResponseDTO } from '../models/UserSystemMessageResponseDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SystemMessagesService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * System message creation
     * @returns any System message successfully created
     * @throws ApiError
     */
    public createSystemMessage({
formData,
}: {
formData: CreateSystemMessageDTO,
}): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/system-message',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                401: `Wrong authorization data`,
                403: `Only an admin with the right isSuperAdmin or marketing.accessSystemMessages can execute the request.`,
            },
        });
    }

    /**
     * Update system message
     * @returns any System message successfully updated
     * @throws ApiError
     */
    public updateSystemMessage({
id,
formData,
}: {
/**
 * Id
 */
id: string,
formData: UpdateSystemMessageDTO,
}): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/system-message/{id}',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                401: `Wrong authorization data`,
                403: `Only an admin with the right isSuperAdmin or marketing.accessSystemMessages can execute the request.`,
                404: `System Message not found`,
            },
        });
    }

    /**
     * Delete system message
     * @returns any System message successfully deleted
     * @throws ApiError
     */
    public deleteSystemMessage({
id,
}: {
/**
 * Id
 */
id: string,
}): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/system-message/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Wrong authorization data`,
                403: `Only an admin with the right isSuperAdmin or isDelete can execute the request.`,
                404: `System message not found`,
            },
        });
    }

    /**
     * System message
     * @returns SystemMessageResponseDTO System message
     * @throws ApiError
     */
    public getSystemMessage({
id,
}: {
/**
 * Id
 */
id: string,
}): CancelablePromise<SystemMessageResponseDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/system-message/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Wrong authorization data`,
                403: `Only an admin with the right isSuperAdmin or marketing.accessSystemMessages can execute the request.`,
            },
        });
    }

    /**
     * System message list
     * @returns PageSystemMessageListResponseDTO List of system messages
     * @throws ApiError
     */
    public getSystemMessageList({
search,
status,
userRole,
segmentation,
cities,
page = 1,
limit = 10,
pageFilter,
isCount,
}: {
/**
 * Used to filter data by title
 */
search?: string,
/**
 * Can be used by the admin.
 */
status?: 'active' | 'hidden',
/**
 * Can be used by the admin.
 */
userRole?: 'client' | 'talent' | 'admin',
/**
 * Can be used by the admin.
 */
segmentation?: 'all' | 'new' | 'notActive' | 'noBooking' | 'active' | 'Free' | 'Elite' | 'Premium' | 'updated' | 'boosted',
/**
 * Can be used by the admin. Send the _id
 */
cities?: Array<string>,
page?: number,
/**
 * If 0 is specified, we will return the data without a limit
 */
limit?: number,
/**
 * Used to filter the received data. We get data where the startDate is less than the specified date. If possible, use this to get the following data instead of the "page" parameter as it works faster.
 */
pageFilter?: string,
/**
 * If it actively returns the quantity.
 */
isCount?: boolean,
}): CancelablePromise<PageSystemMessageListResponseDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/system-message/list',
            query: {
                'search': search,
                'status': status,
                'userRole': userRole,
                'segmentation': segmentation,
                'cities': cities,
                'page': page,
                'limit': limit,
                'pageFilter': pageFilter,
                'isCount': isCount,
            },
            errors: {
                401: `Wrong authorization data`,
                403: `Only an admin with the right isSuperAdmin or marketing.accessSystemMessages can execute the request.`,
            },
        });
    }

    /**
     * SystemMessage Count
     * @returns number SystemMessage Count
     * @throws ApiError
     */
    public getSystemMessageCount({
search,
status,
userRole,
segmentation,
cities,
}: {
/**
 * Used to filter data by title
 */
search?: string,
/**
 * Can be used by the admin.
 */
status?: 'active' | 'hidden',
/**
 * Can be used by the admin.
 */
userRole?: 'client' | 'talent' | 'admin',
/**
 * Can be used by the admin.
 */
segmentation?: 'all' | 'new' | 'notActive' | 'noBooking' | 'active' | 'Free' | 'Elite' | 'Premium' | 'updated' | 'boosted',
/**
 * Can be used by the admin. Send the _id
 */
cities?: Array<string>,
}): CancelablePromise<number> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/system-message/count',
            query: {
                'search': search,
                'status': status,
                'userRole': userRole,
                'segmentation': segmentation,
                'cities': cities,
            },
            errors: {
                401: `Wrong authorization data`,
            },
        });
    }

    /**
     * User system message list
     * @returns UserSystemMessageResponseDTO User system message list
     * @throws ApiError
     */
    public getUserSystemMessage({
page = 1,
limit = 10,
pageFilter,
}: {
page?: number,
/**
 * If 0 is specified, we will return the data without a limit
 */
limit?: number,
/**
 * Used to filter the received data. We get data where the startDate is less than the specified date. If possible, use this to get the following data instead of the "page" parameter as it works faster.
 */
pageFilter?: string,
}): CancelablePromise<Array<UserSystemMessageResponseDTO>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/system-message/user/list',
            query: {
                'page': page,
                'limit': limit,
                'pageFilter': pageFilter,
            },
            errors: {
                401: `Wrong authorization data`,
            },
        });
    }

}
