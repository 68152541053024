/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateHideUserDTO } from '../models/CreateHideUserDTO';
import type { PageUserBlockResponseDTO } from '../models/PageUserBlockResponseDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class BlockService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Block User
     * @returns any User blocked
     * @throws ApiError
     */
    public blockUser({
id,
}: {
/**
 * Id
 */
id: string,
}): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/block/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Invalid authorization data`,
                404: `User not found`,
                409: `User already blocked`,
            },
        });
    }

    /**
     * Unblock User
     * @returns any User unblocked
     * @throws ApiError
     */
    public unblockUser({
id,
}: {
/**
 * Id
 */
id: string,
}): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/block/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Invalid authorization data`,
                404: `User not found`,
                409: `User not blocked`,
            },
        });
    }

    /**
     * Hide User
     * @returns any User Hide
     * @throws ApiError
     */
    public hideUser({
id,
requestBody,
}: {
/**
 * Id
 */
id: string,
requestBody: CreateHideUserDTO,
}): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/block/hide/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Invalid authorization data`,
                403: `Only an admin with the right isSuperAdmin can execute the request.`,
                404: `User not found`,
                409: `User already hide`,
            },
        });
    }

    /**
     * Unhide User
     * @returns any User unhide
     * @throws ApiError
     */
    public unHideUser({
id,
requestBody,
}: {
/**
 * Id
 */
id: string,
requestBody: CreateHideUserDTO,
}): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/block/hide/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Invalid authorization data`,
                403: `Only an admin with the right isSuperAdmin can execute the request.`,
                404: `User not found`,
                409: `User not hide`,
            },
        });
    }

    /**
     * Block User List
     * @returns PageUserBlockResponseDTO List of blocked users
     * @throws ApiError
     */
    public blocUserList({
userId,
toRole,
type,
page = 1,
limit = 10,
pageFilter,
isCount,
}: {
/**
 * Can be used by the admin. Send the _id
 */
userId?: string,
/**
 * Only an admin with the right isSuperAdmin
 */
toRole?: 'client' | 'talent' | 'admin',
/**
 * Only an admin with the right isSuperAdmin
 */
type?: 'block' | 'hide',
page?: number,
/**
 * If 0 is specified, we will return the data without a limit
 */
limit?: number,
/**
 * Used to filter the received data. We get data where the creation date is less than the specified date. If possible, use this to get the following data instead of the "page" parameter as it works faster.
 */
pageFilter?: string,
/**
 * If it actively returns the quantity.
 */
isCount?: boolean,
}): CancelablePromise<PageUserBlockResponseDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/block/list',
            query: {
                'userId': userId,
                'toRole': toRole,
                'type': type,
                'page': page,
                'limit': limit,
                'pageFilter': pageFilter,
                'isCount': isCount,
            },
            errors: {
                401: `Invalid authorization data`,
            },
        });
    }

    /**
     * BlocUser Count
     * @returns number BlocUser Count
     * @throws ApiError
     */
    public getBlocUserCount({
userId,
toRole,
type,
}: {
/**
 * Can be used by the admin. Send the _id
 */
userId?: string,
/**
 * Only an admin with the right isSuperAdmin
 */
toRole?: 'client' | 'talent' | 'admin',
/**
 * Only an admin with the right isSuperAdmin
 */
type?: 'block' | 'hide',
}): CancelablePromise<number> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/block/count',
            query: {
                'userId': userId,
                'toRole': toRole,
                'type': type,
            },
            errors: {
                401: `Invalid authorization data`,
            },
        });
    }

}
