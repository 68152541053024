/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CityPopulateResponseDTO } from './CityPopulateResponseDTO';
import type { UserDocumentsPublicResponseDTO } from './UserDocumentsPublicResponseDTO';
import type { UserEventPreferencesResponseDTO } from './UserEventPreferencesResponseDTO';
import type { UserPopularityResponseDTO } from './UserPopularityResponseDTO';

export type ProfileResponseDTO = {
    _id: string;
    phone: string;
    email: string;
    firstName: string;
    lastName: string;
    legalName: string;
    city: CityPopulateResponseDTO;
    avatar: string;
    photos: Array<string>;
    video: string;
    languages: Array<string>;
    interests: Array<string>;
    bio: string;
    reviewSum: number;
    reviewCount: number;
    rating: number;
    gender: ProfileResponseDTO.gender;
    role: ProfileResponseDTO.role;
    status: ProfileResponseDTO.status;
    /**
     * There is a role client
     */
    subscriptionTitle?: string;
    /**
     * There is a role talent
     */
    nicknameInstagram?: string;
    /**
     * There is a role talent
     */
    headShotImg?: string;
    /**
     * There is a role talent
     */
    bodyShotImg?: string;
    /**
     * There is a role talent
     */
    birthDate?: string;
    /**
     * There is a role talent
     */
    height?: number;
    /**
     * There is a role talent
     */
    dressSize?: string;
    /**
     * There is a role talent
     */
    shoeSize?: string;
    /**
     * There is a role talent
     */
    hairColor?: string;
    eyeColor?: string;
    eventPreferences: UserEventPreferencesResponseDTO;
    /**
     * Does the role have a talent
     */
    popularity?: Array<UserPopularityResponseDTO>;
    likesCount: number;
    bookingsCompleteCount: number;
    bookingsCancelCount: number;
    isFeatured: boolean;
    isLike?: boolean;
    isRecommended?: boolean;
    boostTopDate: string;
    boostCity?: (string | CityPopulateResponseDTO);
    boostTopDateUpdatedAt?: string;
    profileUpdatedAt?: string;
    lastActiveBooking?: string;
    createBookingCount?: number;
    documents?: UserDocumentsPublicResponseDTO;
    createdAt: string;
    updatedAt: string;
};

export namespace ProfileResponseDTO {

    export enum gender {
        MALE = 'male',
        FEMALE = 'female',
    }

    export enum role {
        CLIENT = 'client',
        TALENT = 'talent',
        ADMIN = 'admin',
    }

    export enum status {
        PENDING = 'pending',
        JUNK = 'junk',
        APPROVED = 'approved',
        DECLINED = 'declined',
        BLOCKED = 'blocked',
        DEACTIVATED = 'deactivated',
        DELETED = 'deleted',
    }


}
