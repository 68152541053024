/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type SendEmailTemplateDTO = {
    userRole: SendEmailTemplateDTO.userRole;
    userStatus: SendEmailTemplateDTO.userStatus;
    /**
     * Send the _id
     */
    cities: Array<string>;
    segmentation: SendEmailTemplateDTO.segmentation;
    subject: string;
    templateDesign: Record<string, any>;
    /**
     * User emails. Required if there is no recipientUserFilters
     */
    to?: Array<string>;
    html: string;
};

export namespace SendEmailTemplateDTO {

    export enum userRole {
        CLIENT = 'client',
        TALENT = 'talent',
        ADMIN = 'admin',
    }

    export enum userStatus {
        PENDING = 'pending',
        JUNK = 'junk',
        APPROVED = 'approved',
        DECLINED = 'declined',
        BLOCKED = 'blocked',
        DEACTIVATED = 'deactivated',
        DELETED = 'deleted',
    }

    export enum segmentation {
        ALL = 'all',
        NEW = 'new',
        NOT_ACTIVE = 'notActive',
        NO_BOOKING = 'noBooking',
        ACTIVE = 'active',
        FREE = 'Free',
        ELITE = 'Elite',
        PREMIUM = 'Premium',
        UPDATED = 'updated',
        BOOSTED = 'boosted',
    }


}
