/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { UserListResponseDTO } from './UserListResponseDTO';

export type UserBlockResponseDTO = {
    _id: string;
    by: string;
    to: UserListResponseDTO;
    toRole: UserBlockResponseDTO.toRole;
    type: UserBlockResponseDTO.type;
    createdAt: string;
};

export namespace UserBlockResponseDTO {

    export enum toRole {
        CLIENT = 'client',
        TALENT = 'talent',
        ADMIN = 'admin',
    }

    export enum type {
        BLOCK = 'block',
        HIDE = 'hide',
    }


}
