/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CountryPopulateResponseDTO = {
    _id: string;
    name: string;
    flag: string;
    code: string;
    dialCode: string;
    region: CountryPopulateResponseDTO.region;
};

export namespace CountryPopulateResponseDTO {

    export enum region {
        USA = 'usa',
        EUROPE = 'europe',
        OTHER = 'other',
    }


}
