/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UserPopularityResponseDTO = {
    platform: UserPopularityResponseDTO.platform;
    link: string;
    followers: number;
    accountId: string;
    lastUpdate: string;
};

export namespace UserPopularityResponseDTO {

    export enum platform {
        INSTAGRAM = 'Instagram',
        TIK_TOK = 'TikTok',
        ONLY_FANS = 'OnlyFans',
    }


}
