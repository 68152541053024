/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UserUploadImageDTO = {
    file: Blob;
    name: UserUploadImageDTO.name;
};

export namespace UserUploadImageDTO {

    export enum name {
        AVATAR = 'avatar',
        HEAD_SHOT_IMG = 'headShotImg',
        BODY_SHOT_IMG = 'bodyShotImg',
    }


}
