/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { LuxuryServiceResponseDTO } from './LuxuryServiceResponseDTO';
import type { UserListResponseDTO } from './UserListResponseDTO';

export type LuxuryServiceRequestListResponseDTO = {
    _id: string;
    /**
     * Detailed information is available for the admin
     */
    owner: (string | UserListResponseDTO);
    name: string;
    luxuryService: LuxuryServiceResponseDTO;
    startDate: string;
    /**
     * There is if "luxuryService.category = villa,jets,yacht,cars"
     */
    endDate?: string;
    status: LuxuryServiceRequestListResponseDTO.status;
    createdAt: string;
    updatedAt: string;
};

export namespace LuxuryServiceRequestListResponseDTO {

    export enum status {
        ACTIVE = 'active',
        CANCELED = 'canceled',
        DONE = 'done',
    }


}
