/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CreateReviewDTO = {
    /**
     * The user you want to rate. Send the _id.
     */
    to: string;
    /**
     * The resource that the assessment is associated with. Send the _id
     */
    resource: string;
    resourceType: CreateReviewDTO.resourceType;
    rating: number;
};

export namespace CreateReviewDTO {

    export enum resourceType {
        BOOKING = 'booking',
    }


}
