/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaymentClientSecretResponseDTO } from '../models/PaymentClientSecretResponseDTO';
import type { PaymentMethodResponseDTO } from '../models/PaymentMethodResponseDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PaymentMethodService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Create payment method
     * @returns PaymentClientSecretResponseDTO 
     * @throws ApiError
     */
    public createPaymentMethod(): CancelablePromise<PaymentClientSecretResponseDTO> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/payment/method',
            errors: {
                401: `Invalid authorization data`,
            },
        });
    }

    /**
     * Set default payment method
     * The default payment method is also used to pay for the user's created subscription.
     * @returns any 
     * @throws ApiError
     */
    public setDefaultPaymentMethod({
id,
}: {
id: string,
}): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/payment/method/default/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Not user payment method or stripe payment method error.`,
                401: `Invalid authorization data`,
            },
        });
    }

    /**
     * Delete payment method
     * If the default payment method is deleted and there is a subscription, then we will get an error at the next subscription payment because there is no payment method.
     * @returns any 
     * @throws ApiError
     */
    public deletePaymentMethod({
id,
}: {
id: string,
}): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/payment/method/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Not user payment method or stripe payment method error.`,
                401: `Invalid authorization data`,
            },
        });
    }

    /**
     * List of user payment method
     * @returns PaymentMethodResponseDTO List of user payment method.
     * @throws ApiError
     */
    public getUserPaymentMethodList(): CancelablePromise<Array<PaymentMethodResponseDTO>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/payment/method/list',
            errors: {
                401: `Invalid authorization data`,
            },
        });
    }

    /**
     * List of user payment method
     * @returns PaymentMethodResponseDTO List of user payment method.
     * @throws ApiError
     */
    public getAdminPaymentMethodList({
customerId,
}: {
customerId: string,
}): CancelablePromise<Array<PaymentMethodResponseDTO>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/payment/method/admin/{customerId}/list',
            path: {
                'customerId': customerId,
            },
            errors: {
                401: `Invalid authorization data`,
                403: `Only a user with a admin.`,
            },
        });
    }

}
