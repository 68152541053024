import React, { FC } from "react";
import CityContext, { initialCityState } from "../contexts/city-context";
import Provider, { ProviderProps } from "./provider";

const CityProvider: FC<ProviderProps> = ({ children }) => (
  <Provider initialState={initialCityState} ContextComponent={CityContext}>
    {children}
  </Provider>
);

export default CityProvider;
