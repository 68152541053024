/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { UserListResponseDTO } from './UserListResponseDTO';

export type NotificationResponseDTO = {
    _id: string;
    fromUser?: UserListResponseDTO;
    title: string;
    body: string;
    type: NotificationResponseDTO.type;
    isRead: boolean;
    metadata: Record<string, any>;
    createdAt: string;
    updatedAt: string;
};

export namespace NotificationResponseDTO {

    export enum type {
        TEST = 'test',
        USER_ACCOUNT_APPROVED = 'userAccountApproved',
        USER_ACCOUNT_DECLINED = 'userAccountDeclined',
        USER_ACCOUNT_BLOCKED = 'userAccountBlocked',
        USER_ACCOUNT_REFERRAL_SUCCESS = 'userAccountReferralSuccess',
        USER_ACCOUNT_REFERRAL_PAYMENT = 'userAccountReferralPayment',
        APPROVE_W9 = 'approveW9',
        DECLINE_W9 = 'declineW9',
        MEMBERSHIP_EXPIRED = 'membershipExpired',
        NEW_RECOMMENDATION_TALENT = 'newRecommendationTalent',
        NEW_TALENTS = 'newTalents',
        NEW_EVENT = 'newEvent',
        NEW_TRAVEL = 'newTravel',
        INVITE_USER_BOOKING = 'inviteUserBooking',
        INCLUDE_TALENT_BOOKING = 'includeTalentBooking',
        INCLUDE_CLIENT_BOOKING = 'includeClientBooking',
        EVENT_REMINDER_OWNER = 'eventReminderOwner',
        TRAVEL_REMINDER_OWNER = 'travelReminderOwner',
        EVENT_REMINDER_CLIENT = 'eventReminderClient',
        TRAVEL_REMINDER_CLIENT = 'travelReminderClient',
        EVENT_REMINDER_TALENT = 'eventReminderTalent',
        TRAVEL_REMINDER_TALENT = 'travelReminderTalent',
        FRIENDLY_REMINDER = 'friendlyReminder',
        INCLUDE_APPROVE_CANCEL_BOOKING = 'includeApproveCancelBooking',
        INVITE_APPROVE_BOOKING = 'inviteApproveBooking',
        INVITE_DECLINE_BOOKING = 'inviteDeclineBooking',
        INCLUDE_APPROVE_BOOKING = 'includeApproveBooking',
        NEW_MESSAGE_BOOKING = 'newMessageBooking',
        CHECK_IN_TRAVEL = 'checkInTravel',
        CHECK_IN_TALENT = 'checkInTalent',
        DONE_BOOKING = 'doneBooking',
        BOOKING_PAID_PARTICIPATION = 'bookingPaidParticipation',
        OWNER_INCLUDE_APPROVE_CANCEL_BOOKING = 'ownerIncludeApproveCancelBooking',
        CANCEL_BOOKING = 'cancelBooking',
        UPDATE_BOOKING = 'updateBooking',
        PAID_TALENT_BOOKING = 'paidTalentBooking',
        INCOMPLETE_TALENT_BOOKING = 'incompleteTalentBooking',
        USER_VERIFICATION_IDENTITY_APPROVED = 'userVerificationIdentityApproved',
        USER_VERIFICATION_IDENTITY_DECLINED = 'userVerificationIdentityDeclined',
        ADMIN_INCLUDE_TALENT_BOOKING = 'adminIncludeTalentBooking',
        ADMIN_INCLUDE_CLIENT_BOOKING = 'adminIncludeClientBooking',
        MARKETING = 'marketing',
    }


}
