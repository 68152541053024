import React, { FC, PropsWithChildren } from "react";
import SnackbarProvider from "./providers/snackbar-provider";
import UserProvider from "./providers/user-provider";
import CustomSnackbar from "../snackbar/CustomSnackbar";
import CityProvider from "./providers/city-provider";

const StoreWrapper: FC<PropsWithChildren> = ({ children }) => {
  return (
    <SnackbarProvider>
      <UserProvider>
        <CityProvider>
          {children}
          <CustomSnackbar />
        </CityProvider>
      </UserProvider>
    </SnackbarProvider>
  );
};

export default StoreWrapper;
