import { PageAndLimit, UserListRequest } from "../types/requestApi";

export type SetPageLimitParamsRequest<T extends PageAndLimit> = {
  prev: T;
  data: Partial<T>;
  isCount?: boolean;
};

export const setPageLimitParams = <T extends PageAndLimit>({
  prev,
  data,
  isCount,
}: SetPageLimitParamsRequest<T>): T => {
  const isResetPage = !data.page && !data.pageFilter;

  return {
    ...prev,
    ...data,
    page: isResetPage ? 1 : data.page,
    isCount: isCount && isResetPage,
    pageFilter: isResetPage ? "" : data.pageFilter,
  };
};

export const isConcatNext = (data: UserListRequest): boolean => {
  if ((data.page && data.page > 1) || data.pageFilter) {
    return true;
  } else return false;
};
