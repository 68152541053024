import appClient from "src/api/appClient";
import { SignInResponseDTO } from "src/api/openapi";
import { newDate } from "../utils/moment";

export const tokenHook = () => {
  const getAccessToken = (): string =>
    localStorage.getItem("accessToken") || "";
  
    const getRefreshToken = (): string =>
    localStorage.getItem("refreshToken") || "";

  const getToken = (isRefresh = false): string =>
    !isRefresh ? getAccessToken() : getRefreshToken();

  const setTokens = (data: SignInResponseDTO) => {
    localStorage.setItem("accessToken", data.accessToken);
    localStorage.setItem("refreshToken", data.refreshToken);
    localStorage.setItem("accessTokenExpires", data.accessTokenExpires);
    localStorage.setItem("refreshTokenExpires", data.refreshTokenExpires);
  };

  const removeTokens = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("accessTokenExpires");
    localStorage.removeItem("refreshTokenExpires");
  };

  const setAccessToken = async (): Promise<string> => {
    const refreshToken = localStorage.getItem("refreshToken") || "";
    const refreshTokenExpires = localStorage.getItem("refreshTokenExpires") || "";
    if (!refreshToken || !refreshTokenExpires) return "";
    if (newDate().isAfter(refreshTokenExpires)) return "";

    const data = await appClient.auth.refreshTokens();

    localStorage.setItem("accessToken", data.accessToken);
    localStorage.setItem("accessTokenExpires", data.accessTokenExpires);

    return data.accessToken;
  };

  return {
    getToken,
    getAccessToken,
    getRefreshToken,
    setTokens,
    setAccessToken,
    removeTokens,
  };
};
