import { ErrorDTO } from "src/api/openapi";
import { AnyData } from "../types/common";
import { SnackBarData } from "../types/snackbar";

export const getErrorCodeOrMessageError = (err: AnyData): string => {
  const data: ErrorDTO = err?.body;

  if (!data && err.statusText) return err.statusText;
  if (!data && err.message) return err.message;

  // if (data.errorCode && !data.isCustomMessage) return data.errorCode;

  if (data.message) return data.message;

  return "";
};

export const getError = (error: AnyData, setSnackbar: (data: Partial<SnackBarData>) => void) => {
  const errorMessage = getErrorCodeOrMessageError(error);
  if (errorMessage) {
    setSnackbar({
      open: true,
      message: errorMessage,
      type: "error",
    });
  }
};
