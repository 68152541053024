/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CreateOrUpdateVenueTypeDataDTO = {
    name: string;
    status: CreateOrUpdateVenueTypeDataDTO.status;
    uploadPhotos?: Array<Blob>;
    uploadLogo?: Blob;
    deletePhotos?: Array<string>;
    /**
     * Update the order of photos
     */
    photos?: Array<string>;
};

export namespace CreateOrUpdateVenueTypeDataDTO {

    export enum status {
        ACTIVE = 'active',
        HIDDEN = 'hidden',
    }


}
