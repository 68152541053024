/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { LikeBookingResponseDTO } from './LikeBookingResponseDTO';
import type { LikeUserResponseDTO } from './LikeUserResponseDTO';
import type { LikeVenueResponseDTO } from './LikeVenueResponseDTO';

export type LikeResponseDTO = {
    _id: string;
    user: string;
    resourceType: LikeResponseDTO.resourceType;
    resource: (LikeUserResponseDTO | LikeBookingResponseDTO | LikeVenueResponseDTO);
    createdAt: string;
    updatedAt: string;
};

export namespace LikeResponseDTO {

    export enum resourceType {
        USER = 'User',
        VENUE = 'Venue',
        BOOKING = 'Booking',
    }


}
