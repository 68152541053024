/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateReviewDTO } from '../models/CreateReviewDTO';
import type { PageReviewResponseDTO } from '../models/PageReviewResponseDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ReviewService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Create Review
     * @returns any Review successfully created
     * @throws ApiError
     */
    public reviewCreate({
requestBody,
}: {
requestBody: CreateReviewDTO,
}): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/review',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Resource not found, user not included in it`,
                401: `Invalid authorization data`,
                409: `Reviewed already exist`,
            },
        });
    }

    /**
     * Review List
     * @returns PageReviewResponseDTO List of reviews
     * @throws ApiError
     */
    public getReviewList({
resourceId,
page = 1,
limit = 10,
pageFilter,
isCount,
}: {
resourceId?: string,
page?: number,
/**
 * If 0 is specified, we will return the data without a limit
 */
limit?: number,
/**
 * Used to filter the received data. We get data where the creation date is less than the specified date. If possible, use this to get the following data instead of the "page" parameter as it works faster. If you are sorting list by "updatedAt" date, you need to use "updatedAt" date for this param
 */
pageFilter?: string,
/**
 * If it actively returns the quantity.
 */
isCount?: boolean,
}): CancelablePromise<PageReviewResponseDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/review/list',
            query: {
                'resourceId': resourceId,
                'page': page,
                'limit': limit,
                'pageFilter': pageFilter,
                'isCount': isCount,
            },
            errors: {
                401: `Invalid authorization data`,
            },
        });
    }

    /**
     * Review Count
     * @returns number Review Count
     * @throws ApiError
     */
    public getReviewCount({
resourceId,
}: {
resourceId?: string,
}): CancelablePromise<number> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/review/count',
            query: {
                'resourceId': resourceId,
            },
            errors: {
                401: `Invalid authorization data`,
            },
        });
    }

}
