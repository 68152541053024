/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CreateCharityDTO = {
    name: string;
    /**
     * Send the _id
     */
    country: string;
    category: string;
    description: string;
    status: CreateCharityDTO.status;
    uploadPhotos?: Array<Blob>;
    uploadLogo?: Blob;
};

export namespace CreateCharityDTO {

    export enum status {
        ACTIVE = 'active',
        HIDDEN = 'hidden',
    }


}
