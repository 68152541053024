import { createTheme, ThemeOptions } from "@mui/material/styles";

const typography: ThemeOptions["typography"] = {
  fontFamily: [
    "Lexend",
    // "Inter",
    "-apple-system",
    "BlinkMacSystemFont",
    "Segoe UI",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    "Twemoji Country Flags",
    "sans-serif",
  ].join(","),
  h1: {
    fontSize: "2rem", // 32px
    fontWeight: 500,
  },
  h2: {
    fontSize: "1.75rem", // 28px
    fontWeight: 500,
  },
  h3: {
    fontSize: "1.625rem", // 26px
    fontWeight: 500,
  },
  h4: {
    fontSize: "1.5rem", // 24px
    fontWeight: 500,
  },
  h5: {
    fontSize: "1.375rem", // 22px
    fontWeight: 500,
  },
  h6: {
    fontSize: "1.25rem", // 20px
    fontWeight: 500,
  },
  subtitle1: {
    fontSize: "1rem", // 16px
    fontWeight: 500,
  },
  subtitle2: {
    fontFamily: ["Inter", "Twemoji Country Flags"].join(","),
    fontSize: "1rem", // 16px
    fontWeight: 400,
  },
  body1: {
    fontFamily: ["Inter", "Twemoji Country Flags"].join(","),
    fontSize: "0.875rem", // 14px
    fontWeight: 400,
  },
  body2: {
    fontFamily: ["Inter", "Twemoji Country Flags"].join(","),
    fontSize: "0.75rem", // 12px
    fontWeight: 400,
  },
  button: {
    fontSize: "1rem", // 16px
    textTransform: "none",
  },
};

export const lightTheme = createTheme({
  palette: {
    background: {
      default: "#ededed",
      paper: "#fff",
    },
    primary: {
      main: "#000",
      contrastText: "#fff",
    },
    secondary: {
      main: "#5CADCB",
      contrastText: "#fff",
    },
    text: {
      disabled: "rgba(0, 0, 0, 0.38)",
      primary: "#01010B",
      secondary: "#70787C",
    },
    mode: "light",
  },
  typography,
  components: {
    MuiCssBaseline: {
      styleOverrides: (theme) => ({
        a: {
          color: theme.palette.secondary.main,
          textDecoration: "none",
        },
      }),
    },
    MuiLink: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.secondary.main,
        }),
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          backgroundColor: "#fff",
          boxShadow: "none",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: (props) => {
          return {
            padding: "12px 16px",
            borderRadius: 100,
            "&:hover": props.ownerState.variant === "contained" && {
              backgroundColor: "rgba(0, 0, 0, 0.75)",
            },
          };
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: (props) => {
          return {
            ...(props.ownerState.size === "medium" && {
              ".MuiOutlinedInput-root": {
                padding: "4.5px 11px",
              },
            }),
          };
        },
      },
    },
    MuiInputLabel: {
      defaultProps: {
        shrink: true,
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: (props) => {
          return {
            ...(props.ownerState.size === "medium" && {
              "&.MuiInputBase-multiline": {
                padding: "12px 16px",
              },
              ".MuiInputBase-input": {
                padding: 0,
              },
              padding: "12px 16px",
            }),
          };
        },
      },
    },
    // MuiMenu: {
    //   styleOverrides: {
    //     root: {
    //       "&.avatar-menu": {
    //         backgroundColor: "#fff",
    //         color: "#fc9430",
    //         ".MuiListItemIcon-root": {
    //           color: "inherit",
    //         },
    //       },
    //     },
    //   },
    // },
    // MuiDrawer: {
    //   styleOverrides: {
    //     paper: {
    //       backgroundColor: "#2b323d",
    //       color: "#fff",
    //       ".MuiList-root .Mui-selected": {
    //         backgroundColor: "#fc9430",
    //       },
    //       ".MuiListItemIcon-root": {
    //         color: "inherit",
    //       },
    //     },
    //   },
    // },
    // MuiBottomNavigationAction: {
    //   styleOverrides: {
    //     root: {
    //       maxWidth: "20px",
    //       ".Mui-selected": {
    //         color: "#fc9430",
    //       },
    //     },
    //   },
    // },
    // MuiTabs: {
    //   styleOverrides: {
    //     flexContainer: {
    //       "& .MuiTabs-flexContainer": {
    //         width: "100%",
    //         color: "#2b323d",
    //       },
    //       "& .MuiTab-root": {
    //         color: "#2b323d",
    //       },
    //       "& .Mui-selected": {
    //         color: "#2b323d",
    //       },
    //     },
    //   },
    // },
  },
});

// export const darkTheme = createTheme({
//   palette: {
//     mode: "dark",
//   },
// });
