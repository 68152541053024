import { UserInfoResponseDTO } from "src/api/openapi";
import { AnyData, KeyofUserAdminRights } from "../types/common";

export const getUserFullName = (
  user: Pick<UserInfoResponseDTO, "firstName" | "lastName">
): string => `${user.firstName} ${user.lastName}`;

export const isRoleTalent = (role: UserInfoResponseDTO.role) =>
  UserInfoResponseDTO.role.TALENT === role;

export const isRoleAdmin = (role: UserInfoResponseDTO.role) =>
  UserInfoResponseDTO.role.ADMIN === role;

const getObjKeysValue = (obj: AnyData, path: string[]) => {
  for (let i = 0; i < path.length; i++) {
    obj = obj[path[i]];
    if (!obj) break;
  }
  return obj;
};

export const checkAdminRights = (
  user: UserInfoResponseDTO,
  adminRightKeys: KeyofUserAdminRights[],
): boolean => {
  if (!adminRightKeys || adminRightKeys.length === 0) return true;
  if (!isRoleAdmin(user.role) || !user?.adminRights) return false;
  if (user.adminRights.isSuperAdmin) return true;

  return (
    Object.entries(user.adminRights).filter(([key, val]) => {
      return adminRightKeys.some((right) => {
        const [rightKey, ...keys] = right?.split(".") || "";
        return key === rightKey && getObjKeysValue(val, keys);
      });
    }).length > 0
  );
};

export const getUserRoleLink = (role: UserInfoResponseDTO.role): string => {
  switch (role) {
    case UserInfoResponseDTO.role.TALENT:
      return "talents";
    case UserInfoResponseDTO.role.CLIENT:
      return "clients";
    case UserInfoResponseDTO.role.ADMIN:
      return "";
    default:
      return "";
  }
};
