import React, { PropsWithChildren } from "react";
import { Backdrop, Box, CircularProgress } from "@mui/material";

interface LoaderProps {
  isMinHeight?: boolean;
  minHeight?: string;
  size?: number;
  color?:
    | "primary"
    | "secondary"
    | "error"
    | "info"
    | "success"
    | "warning"
    | "inherit";
}

interface LoaderBackdropProps extends PropsWithChildren {
  isLoading: boolean;
}

const Loader: React.FC<LoaderProps> = ({
  isMinHeight = false,
  minHeight = "",
  color = "info",
  size,
}) => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight={isMinHeight ? "100vh" : minHeight}
    >
      <CircularProgress color={color} size={size} />
    </Box>
  );
};

export const LoaderBackdrop: React.FC<LoaderBackdropProps> = ({
  isLoading,
  children,
}) => {
  return (
    <Box
      position={"relative"}
      sx={{
        opacity: isLoading ? 0.4 : 1,
      }}
    >
      {children}

      <Backdrop
        open={isLoading}
        sx={{ background: "none", position: "absolute" }}
      >
        <Loader />
      </Backdrop>
    </Box>
  );
};

export default Loader;
