import React, { Suspense, lazy } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import PrivateRouter from "./routes/PrivateRouter";
import ThemeWrapper from "./components/theme/ThemeWrapper";
import StoreWrapper from "./components/store/StoreWrapper";
import { polyfillCountryFlagEmojis } from "country-flag-emoji-polyfill";
import Loader from "./components/UI/Loader";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ForgotPassword = lazy(() => import("./pages/public/ForgotPassword"));
const ResetPassword = lazy(() => import("./pages/public/ResetPassword"));
const Login = lazy(() => import("./pages/public/Login"));
const StripeAccount = lazy(() => import("./pages/public/stripe/account/StripeAccount"));

function App() {
  polyfillCountryFlagEmojis();
  return (
    <StoreWrapper>
      <ThemeWrapper>
        <BrowserRouter>
          <Suspense fallback={<Loader isMinHeight />}>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route
                path="/reset-password/:token/:email"
                element={<ResetPassword />}
              />
              <Route
                path="/stripe-account/:status?"
                element={<StripeAccount />}
              />
              <Route path="/*" element={<PrivateRouter />} />
            </Routes>
          </Suspense>
        </BrowserRouter>
      </ThemeWrapper>
    </StoreWrapper>
  );
}

export default App;
