/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UpdateStatusPayoutPaypalDTO = {
    /**
     * Send the _id
     */
    transactionId: string;
    status: UpdateStatusPayoutPaypalDTO.status;
    /**
     * You can add a reason for rejecting the output or an error. Or a success message.
     */
    message?: string;
};

export namespace UpdateStatusPayoutPaypalDTO {

    export enum status {
        CANCELED = 'canceled',
        FAILED = 'failed',
        SUCCEEDED = 'succeeded',
    }


}
