/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { VenueBookingInstructionsDTO } from './VenueBookingInstructionsDTO';
import type { VenueHoursOperationsDTO } from './VenueHoursOperationsDTO';
import type { VenueMinDataDTO } from './VenueMinDataDTO';

export type UpdateAdminVenueDTO = {
    name?: string;
    phone?: string;
    email?: string;
    address?: string;
    /**
     * Send the _id
     */
    city?: string;
    description?: string;
    priceRating?: UpdateAdminVenueDTO.priceRating;
    hoursOperation?: Array<string>;
    lat?: number;
    long?: number;
    /**
     * Send the name of the value instead of the _id
     */
    type?: string;
    experienceType?: UpdateAdminVenueDTO.experienceType;
    status?: UpdateAdminVenueDTO.status;
    isPopular?: boolean;
    uploadPhotos?: Array<Blob>;
    uploadLogo?: Blob;
    deletePhotos?: Array<string>;
    /**
     * Update the order of photos
     */
    photos?: Array<string>;
    /**
     * Send the name of the value instead of the _id.
     */
    kitchens?: Array<string>;
    /**
     * Send the name of the value instead of the _id.
     */
    diningStyles?: Array<string>;
    /**
     * Send the name of the value instead of the _id.
     */
    dressCodes?: Array<string>;
    /**
     * Users who will be able to see this entry. Send the _id
     */
    onlyUsers?: Array<string>;
    hoursOperations?: VenueHoursOperationsDTO;
    venueMinData?: VenueMinDataDTO;
    bookingInstructions?: VenueBookingInstructionsDTO;
};

export namespace UpdateAdminVenueDTO {

    export enum priceRating {
        LOW = 'low',
        MEDIUM = 'medium',
        HIGH = 'high',
        VERY_HIGH = 'veryHigh',
    }

    export enum experienceType {
        EVENT = 'event',
        TRAVEL = 'travel',
    }

    export enum status {
        PENDING = 'pending',
        ACTIVE = 'active',
        HIDDEN = 'hidden',
        DELETED = 'deleted',
    }


}
