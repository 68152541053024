/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateOrUpdateVenueTypeDataDTO } from '../models/CreateOrUpdateVenueTypeDataDTO';
import type { PageVenueTypeDataResponseDTO } from '../models/PageVenueTypeDataResponseDTO';
import type { VenueTypeDataResponseDTO } from '../models/VenueTypeDataResponseDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class VenueTypeDataService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Create Venue Type Data
     * @returns VenueTypeDataResponseDTO Venue Type Data
     * @throws ApiError
     */
    public createVenueTypeData({
formData,
}: {
formData: CreateOrUpdateVenueTypeDataDTO,
}): CancelablePromise<VenueTypeDataResponseDTO> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/venue-type-data',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                401: `Invalid authorization data`,
                403: `Only an admin with the right isSuperAdmin can execute the request.`,
            },
        });
    }

    /**
     * Update Venue Type Data
     * @returns VenueTypeDataResponseDTO Venue Type Data
     * @throws ApiError
     */
    public updateVenueTypeData({
id,
formData,
}: {
/**
 * Id
 */
id: string,
formData: CreateOrUpdateVenueTypeDataDTO,
}): CancelablePromise<VenueTypeDataResponseDTO> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/venue-type-data/{id}',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                401: `Invalid authorization data`,
                403: `Only an admin with the right isSuperAdmin can execute the request.`,
            },
        });
    }

    /**
     * Venue Type Data Delete
     * @returns any Venue Type Data Deleted
     * @throws ApiError
     */
    public deleteVenueTypeData({
id,
}: {
/**
 * Id
 */
id: string,
}): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/venue-type-data/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Invalid authorization data`,
                403: `Only an admin with the right isSuperAdmin can execute the request.`,
                404: `Venue Type Data not found`,
            },
        });
    }

    /**
     * Venue Type Data
     * @returns VenueTypeDataResponseDTO Venue Type Data
     * @throws ApiError
     */
    public getVenueTypeData({
id,
}: {
/**
 * Id
 */
id: string,
}): CancelablePromise<VenueTypeDataResponseDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/venue-type-data/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Invalid authorization data`,
                404: `Venue Type Data not found`,
            },
        });
    }

    /**
     * Venue type data List
     * @returns PageVenueTypeDataResponseDTO List of venue type data
     * @throws ApiError
     */
    public getVenueTypeDataList({
search,
status,
page = 1,
limit = 10,
isCount,
}: {
/**
 * Used to filter data by name
 */
search?: string,
/**
 * Can be used by the admin.
 */
status?: 'active' | 'hidden',
page?: number,
/**
 * If 0 is specified, we will return the data without a limit
 */
limit?: number,
/**
 * If it actively returns the quantity.
 */
isCount?: boolean,
}): CancelablePromise<PageVenueTypeDataResponseDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/venue-type-data/list',
            query: {
                'search': search,
                'status': status,
                'page': page,
                'limit': limit,
                'isCount': isCount,
            },
            errors: {
                401: `Invalid authorization data`,
            },
        });
    }

    /**
     * Venue Count
     * @returns number Venue Type Data Count
     * @throws ApiError
     */
    public getVenueTypeDataCount({
search,
status,
}: {
/**
 * Used to filter data by name
 */
search?: string,
/**
 * Can be used by the admin.
 */
status?: 'active' | 'hidden',
}): CancelablePromise<number> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/venue-type-data/count',
            query: {
                'search': search,
                'status': status,
            },
            errors: {
                401: `Invalid authorization data`,
            },
        });
    }

}
