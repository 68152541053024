/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreatePayoutDTO } from '../models/CreatePayoutDTO';
import type { PayoutDetailsStripeBankResponseDTO } from '../models/PayoutDetailsStripeBankResponseDTO';
import type { PayoutDetailsStripeCardResponseDTO } from '../models/PayoutDetailsStripeCardResponseDTO';
import type { UpdateStatusPayoutPaypalDTO } from '../models/UpdateStatusPayoutPaypalDTO';
import type { WalletAccountLinkResponseDTO } from '../models/WalletAccountLinkResponseDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class WalletService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Create Account
     * @returns WalletAccountLinkResponseDTO Account Link object that includes a single-use Stripe URL that the platform can redirect their user to in order to take them through the Connect Onboarding flow.
     * @throws ApiError
     */
    public createWalletAccount(): CancelablePromise<WalletAccountLinkResponseDTO> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/wallet',
            errors: {
                400: `The wallet has already been created.`,
                401: `Invalid authorization data`,
            },
        });
    }

    /**
     * Get Balance
     * @returns number Get user balance
     * @throws ApiError
     */
    public getBalance(): CancelablePromise<number> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/wallet',
            errors: {
                401: `Invalid authorization data`,
            },
        });
    }

    /**
     * Create Account Link
     * Creates an Account Link object that includes a single-use Stripe URL that the platform can redirect their user to in order to take them through the Connect Onboarding flow.
     * @returns WalletAccountLinkResponseDTO 
     * @throws ApiError
     */
    public createWalletAccountLink(): CancelablePromise<WalletAccountLinkResponseDTO> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/wallet/link',
            errors: {
                400: `The account wallet was not found.`,
                401: `Invalid authorization data`,
            },
        });
    }

    /**
     * Create Account Login Link
     * Link for authorization in Stripe connect account.
     * @returns string 
     * @throws ApiError
     */
    public createWalletAccountLoginLink(): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/wallet/login-link',
            errors: {
                400: `The account wallet was not found.`,
                401: `Invalid authorization data`,
            },
        });
    }

    /**
     * Create Payout
     * @returns any 
     * @throws ApiError
     */
    public createPayout({
requestBody,
}: {
requestBody: CreatePayoutDTO,
}): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/wallet/payout',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `The account wallet was not found. There are not enough funds on the balance.`,
                401: `Invalid authorization data`,
            },
        });
    }

    /**
     * Admin Create Payout
     * @returns any 
     * @throws ApiError
     */
    public adminCreatePayout({
id,
requestBody,
}: {
/**
 * Id
 */
id: string,
requestBody: CreatePayoutDTO,
}): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/wallet/payout/user/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `The account wallet was not found. There are not enough funds on the balance.`,
                401: `Invalid authorization data`,
                403: `Only a user with the role admin can make a request. The admin must have the rights to perform this operation isSuperAdmin or transaction.addTransaction.`,
            },
        });
    }

    /**
     * Update Status Payout Paypal
     * @returns any 
     * @throws ApiError
     */
    public updateStatusPayoutPaypal({
requestBody,
}: {
requestBody: UpdateStatusPayoutPaypalDTO,
}): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/wallet/payout-status-paypal',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Transaction not found. The status should be pending, enter the expiration date of payout and the payout type should be paypal`,
                401: `Invalid authorization data`,
                403: `Only a user with the role admin can make a request. The admin must have the rights to perform this operation isSuperAdmin or transaction.addTransaction.`,
            },
        });
    }

    /**
     * Stripe Payout Method
     * @returns any PayoutDetailsStripeBankResponseDTO or PayoutDetailsStripeCardResponseDTO
     * @throws ApiError
     */
    public getStripePayoutMethod(): CancelablePromise<(PayoutDetailsStripeBankResponseDTO | PayoutDetailsStripeCardResponseDTO)> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/wallet/stripe-payout-method',
            errors: {
                400: `The account wallet was not found.`,
                401: `Invalid authorization data`,
            },
        });
    }

    /**
     * Stripe Payout Method
     * @returns any PayoutDetailsStripeBankResponseDTO or PayoutDetailsStripeCardResponseDTO
     * @throws ApiError
     */
    public getStripePayoutMethod1({
accountId,
}: {
accountId: string,
}): CancelablePromise<(PayoutDetailsStripeBankResponseDTO | PayoutDetailsStripeCardResponseDTO)> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/wallet/admin/{accountId}/stripe-payout-method',
            path: {
                'accountId': accountId,
            },
            errors: {
                400: `The account wallet was not found.`,
                401: `Invalid authorization data`,
                403: `Only a user with a admin.`,
            },
        });
    }

}
