/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AcceptPushNotificationDTO } from '../models/AcceptPushNotificationDTO';
import type { DisablePushNotificationDTO } from '../models/DisablePushNotificationDTO';
import type { NotificationResponseDTO } from '../models/NotificationResponseDTO';
import type { ReadOrDeleteNotificationsDTO } from '../models/ReadOrDeleteNotificationsDTO';
import type { TestCreateNotificationDTO } from '../models/TestCreateNotificationDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class NotificationService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Create a text notification
     * This request will be deleted later
     * @returns any 
     * @throws ApiError
     */
    public testCreateNotification({
requestBody,
}: {
requestBody: TestCreateNotificationDTO,
}): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/notification/test',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Invalid authorization data`,
            },
        });
    }

    /**
     * Set the read status for notifications
     * @returns any 
     * @throws ApiError
     */
    public readNotifications({
requestBody,
}: {
requestBody: ReadOrDeleteNotificationsDTO,
}): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/notification/read',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Incorrect data`,
                401: `Invalid authorization data`,
            },
        });
    }

    /**
     * Delete Notifications
     * @returns any 
     * @throws ApiError
     */
    public deleteNotifications({
requestBody,
}: {
requestBody: ReadOrDeleteNotificationsDTO,
}): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/notification',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Incorrect data`,
                401: `Invalid authorization data`,
            },
        });
    }

    /**
     * Notification list
     * @returns NotificationResponseDTO User Notifications
     * @throws ApiError
     */
    public getNotificationList({
limit = 10,
isRead,
lastDate,
createdAtFrom,
createdAtTo,
}: {
/**
 * If 0 is specified, we will return the data without a limit
 */
limit?: number,
isRead?: boolean,
/**
 * Used to filter the received data. We get data where the creation date is less than the specified date.
 */
lastDate?: string,
createdAtFrom?: string,
createdAtTo?: string,
}): CancelablePromise<Array<NotificationResponseDTO>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/notification/list',
            query: {
                'limit': limit,
                'isRead': isRead,
                'lastDate': lastDate,
                'createdAtFrom': createdAtFrom,
                'createdAtTo': createdAtTo,
            },
            errors: {
                401: `Invalid authorization data`,
            },
        });
    }

    /**
     * Number of notifications
     * @returns number Number of user notifications
     * @throws ApiError
     */
    public getNotificationCount({
isRead,
createdAtFrom,
createdAtTo,
}: {
isRead?: boolean,
createdAtFrom?: string,
createdAtTo?: string,
}): CancelablePromise<number> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/notification/count',
            query: {
                'isRead': isRead,
                'createdAtFrom': createdAtFrom,
                'createdAtTo': createdAtTo,
            },
            errors: {
                401: `Invalid authorization data`,
            },
        });
    }

    /**
     * Allow push notifications
     * @returns any 
     * @throws ApiError
     */
    public acceptPushNotification({
requestBody,
}: {
requestBody: AcceptPushNotificationDTO,
}): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/notification/accept-push',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Invalid authorization data`,
            },
        });
    }

    /**
     * Block push notifications
     * @returns any 
     * @throws ApiError
     */
    public disablePushNotification({
requestBody,
}: {
requestBody: DisablePushNotificationDTO,
}): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/notification/disable-push',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Invalid authorization data`,
            },
        });
    }

}
