/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { VenueMinDataDTO } from './VenueMinDataDTO';

export type CreateCityDTO = {
    file?: Blob;
    /**
     * Send the _id
     */
    country: string;
    name: string;
    timezone: string;
    status: CreateCityDTO.status;
    venueDefault: VenueMinDataDTO;
};

export namespace CreateCityDTO {

    export enum status {
        ACTIVE = 'active',
        HIDDEN = 'hidden',
    }


}
