/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CityResponseDTO } from '../models/CityResponseDTO';
import type { CreateCityDTO } from '../models/CreateCityDTO';
import type { PageCityResponseDTO } from '../models/PageCityResponseDTO';
import type { UpdateCityDTO } from '../models/UpdateCityDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CityService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * City creation
     * @returns CityResponseDTO City successfully created
     * @throws ApiError
     */
    public createCity({
formData,
}: {
formData: CreateCityDTO,
}): CancelablePromise<CityResponseDTO> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/city',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                401: `Wrong authorization data`,
                403: `Only an admin with the right isSuperAdmin can execute the request.`,
            },
        });
    }

    /**
     * Update city name
     * @returns CityResponseDTO City successfully updated
     * @throws ApiError
     */
    public updateCity({
id,
formData,
}: {
/**
 * Id
 */
id: string,
formData: UpdateCityDTO,
}): CancelablePromise<CityResponseDTO> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/city/{id}',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                401: `Wrong authorization data`,
                403: `Only an admin with the right isSuperAdmin can execute the request.`,
                404: `City not found`,
            },
        });
    }

    /**
     * Delete city
     * @returns void 
     * @throws ApiError
     */
    public deleteCity({
id,
}: {
/**
 * Id
 */
id: string,
}): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/city/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Wrong authorization data`,
                403: `Only an admin with the right isSuperAdmin can execute the request.`,
                404: `City not found`,
            },
        });
    }

    /**
     * City
     * @returns CityResponseDTO City
     * @throws ApiError
     */
    public getCity({
id,
}: {
/**
 * Id
 */
id: string,
}): CancelablePromise<CityResponseDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/city/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Wrong authorization data`,
                404: `City not found`,
            },
        });
    }

    /**
     * City list
     * @returns PageCityResponseDTO List of city
     * @throws ApiError
     */
    public getCityList({
search,
country,
status,
isAdminRightsCity,
page = 1,
limit = 10,
isCount,
}: {
/**
 * Used to filter by name
 */
search?: string,
/**
 * Send the _id
 */
country?: string,
/**
 * Can be used by the admin.
 */
status?: 'active' | 'hidden',
/**
 * Get only the cities available to the administrator. If the admin is a super admin, then he will get all the cities.
 */
isAdminRightsCity?: boolean,
page?: number,
/**
 * If 0 is specified, we will return the data without a limit
 */
limit?: number,
/**
 * If it actively returns the quantity.
 */
isCount?: boolean,
}): CancelablePromise<PageCityResponseDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/city/list',
            query: {
                'search': search,
                'country': country,
                'status': status,
                'isAdminRightsCity': isAdminRightsCity,
                'page': page,
                'limit': limit,
                'isCount': isCount,
            },
        });
    }

    /**
     * City Count
     * @returns number City Count
     * @throws ApiError
     */
    public getCityCount({
search,
country,
status,
isAdminRightsCity,
}: {
/**
 * Used to filter by name
 */
search?: string,
/**
 * Send the _id
 */
country?: string,
/**
 * Can be used by the admin.
 */
status?: 'active' | 'hidden',
/**
 * Get only the cities available to the administrator. If the admin is a super admin, then he will get all the cities.
 */
isAdminRightsCity?: boolean,
}): CancelablePromise<number> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/city/count',
            query: {
                'search': search,
                'country': country,
                'status': status,
                'isAdminRightsCity': isAdminRightsCity,
            },
        });
    }

}
