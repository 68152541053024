import React, { FC, PropsWithChildren } from "react";
import { ThemeProvider } from "@mui/material/styles";
import { lightTheme } from "./customizationTheme";
import CssBaseline from "@mui/material/CssBaseline/CssBaseline";

const ThemeWrapper: FC<PropsWithChildren> = ({ children }) => {
  return (
    <ThemeProvider theme={lightTheme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

export default ThemeWrapper;
