/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ChangeEventPreferencesDTO } from './ChangeEventPreferencesDTO';
import type { UserNotificationSettingsDTO } from './UserNotificationSettingsDTO';

export type ChangeUserDataDTO = {
    firstName?: string;
    lastName?: string;
    legalName?: string;
    phone?: string;
    /**
     * Send the _id
     */
    city?: string;
    /**
     * Required for the talent role
     */
    height?: number;
    /**
     * Required for the talent role
     */
    dressSize?: string;
    /**
     * Required for the talent role
     */
    shoeSize?: string;
    /**
     * Required for the talent role
     */
    hairColor?: string;
    eyeColor?: string;
    gender?: ChangeUserDataDTO.gender;
    nicknameInstagram?: string;
    interests?: Array<string>;
    bio?: string;
    languages?: Array<string>;
    birthDate?: string;
    paypalEmail?: string;
    eventPreferences?: ChangeEventPreferencesDTO;
    notifications?: UserNotificationSettingsDTO;
};

export namespace ChangeUserDataDTO {

    export enum gender {
        MALE = 'male',
        FEMALE = 'female',
    }


}
