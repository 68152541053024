/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AdminAddRemoveRecommendedUserDTO } from '../models/AdminAddRemoveRecommendedUserDTO';
import type { AdminChangeUserDTO } from '../models/AdminChangeUserDTO';
import type { AdminProfileResponseDTO } from '../models/AdminProfileResponseDTO';
import type { AdminUserVerificationDTO } from '../models/AdminUserVerificationDTO';
import type { ChangeAdminPasswordDTO } from '../models/ChangeAdminPasswordDTO';
import type { ChangeAdminRightsDTO } from '../models/ChangeAdminRightsDTO';
import type { ChangeUserAdditionalFeaturesDTO } from '../models/ChangeUserAdditionalFeaturesDTO';
import type { ChangeUserAmbassadorDTO } from '../models/ChangeUserAmbassadorDTO';
import type { ChangeUserBoostTopDTO } from '../models/ChangeUserBoostTopDTO';
import type { ChangeUserBoostTopResponseDTO } from '../models/ChangeUserBoostTopResponseDTO';
import type { CreateAdminUserDTO } from '../models/CreateAdminUserDTO';
import type { PageAdminUserListResponseDTO } from '../models/PageAdminUserListResponseDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AdminUserService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Create Admin User
     * @returns any 
     * @throws ApiError
     */
    public createAdminUser({
requestBody,
}: {
requestBody: CreateAdminUserDTO,
}): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/admin/user',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Invalid authorization data`,
                403: `Only an admin with isSuperAdmin rights can add a new administrator.`,
            },
        });
    }

    /**
     * Change Admin Password
     * @returns any Password successfully changed
     * @throws ApiError
     */
    public changeAdminPassword({
requestBody,
}: {
requestBody: ChangeAdminPasswordDTO,
}): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/admin/user/password',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Passwords doesn't match`,
                401: `Invalid authorization data`,
                403: `Change Admin Password`,
                404: `User not found`,
            },
        });
    }

    /**
     * Update user status
     * @returns any 
     * @throws ApiError
     */
    public updateUserStatus({
requestBody,
}: {
requestBody: AdminUserVerificationDTO,
}): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/admin/user/update-status',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `You cannot update your status`,
                401: `Invalid authorization data`,
                403: `Only an admin with the right is Approve Clients can update status the client. Only an admin with the right [client.changeAccount, talent.changeAccount] can update status the talent. Only an admin with the right isSuperAdmin can update status the admin.`,
                404: `User not found`,
            },
        });
    }

    /**
     * Change User Profile
     * @returns any 
     * @throws ApiError
     */
    public changeUserProfile({
id,
requestBody,
}: {
/**
 * Id
 */
id: string,
requestBody: AdminChangeUserDTO,
}): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/admin/user/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Invalid authorization data`,
                403: `Only an admin with the right isSuperAdmin or [client.changeAccount, talent.changeAccount] or talent.editFeatured(isFeatured update only) can update user profile.`,
                404: `User not found`,
            },
        });
    }

    /**
     * User delete
     * @returns any User deleted
     * @throws ApiError
     */
    public adminDeleteUser({
id,
}: {
/**
 * Id
 */
id: string,
}): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/admin/user/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Invalid authorization data`,
                403: `Only an admin with isSuperAdmin rights can delete user.`,
                404: `User not found`,
            },
        });
    }

    /**
     * User Profile
     * @returns AdminProfileResponseDTO User Profile
     * @throws ApiError
     */
    public getAdminUser({
id,
}: {
/**
 * Id
 */
id: string,
}): CancelablePromise<AdminProfileResponseDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/admin/user/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Invalid authorization data`,
                403: `Only the admin can complete this request.`,
                404: `User not found`,
            },
        });
    }

    /**
     * Add remove recommended
     * @returns any 
     * @throws ApiError
     */
    public addRemoveRecommended({
id,
requestBody,
}: {
/**
 * Id
 */
id: string,
requestBody: AdminAddRemoveRecommendedUserDTO,
}): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/admin/user/{id}/recommended',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Invalid authorization data`,
                403: `Only an admin with the right isSuperAdmin or [client.changeAccount, talent.changeAccount].`,
                404: `User not found`,
            },
        });
    }

    /**
     * Change User Additional Features
     * @returns any 
     * @throws ApiError
     */
    public changeUserAdditionalFeatures({
id,
requestBody,
}: {
/**
 * Id
 */
id: string,
requestBody: ChangeUserAdditionalFeaturesDTO,
}): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/admin/user/{id}/additional-features',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Invalid authorization data`,
                403: `Only an admin with the right isSuperAdmin or [client.changeAdditionalFeatures, talent.changeAdditionalFeatures] can update user profile.`,
                404: `User not found`,
            },
        });
    }

    /**
     * Change User Ambassador
     * @returns any 
     * @throws ApiError
     */
    public changeUserAmbassador({
id,
requestBody,
}: {
/**
 * Id
 */
id: string,
requestBody: ChangeUserAmbassadorDTO,
}): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/admin/user/{id}/ambassador',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Invalid authorization data`,
                403: `Only an admin with the right isSuperAdmin can update user profile.`,
                404: `User not found`,
            },
        });
    }

    /**
     * Change User Boost Top
     * @returns ChangeUserBoostTopResponseDTO 
     * @throws ApiError
     */
    public changeUserBoostTop({
id,
requestBody,
}: {
/**
 * Id
 */
id: string,
requestBody: ChangeUserBoostTopDTO,
}): CancelablePromise<ChangeUserBoostTopResponseDTO> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/admin/user/{id}/boost-top',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Invalid authorization data`,
                403: `Only an admin with the right isSuperAdmin or [client.changeAccount, talent.changeAccount] can update user boost top.`,
                404: `User not found.`,
            },
        });
    }

    /**
     * Update admin rights
     * @returns any 
     * @throws ApiError
     */
    public changeAdminRights({
id,
requestBody,
}: {
/**
 * Id
 */
id: string,
requestBody: ChangeAdminRightsDTO,
}): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/admin/user/{id}/update-admin-rights',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `You cannot update your admin right`,
                401: `Invalid authorization data`,
                403: `Only an admin with isSuperAdmin rights can update admin right.`,
                404: `User not found`,
            },
        });
    }

    /**
     * List of users
     * @returns PageAdminUserListResponseDTO List of users
     * @throws ApiError
     */
    public getAdminUserList({
search,
city,
cities,
genders,
languages,
hairColors,
eyeColors,
dressSizes,
shoeSizes,
isOnlyRecommended,
isFeatured,
isAmbassador,
isBoost,
boostCity,
startAge,
endAge,
heightFrom,
heightTo,
eventPreferences,
sort = 'createdAt',
sortDirection = -1,
createdAtFrom,
createdAtTo,
profileUpdatedAtFrom,
profileUpdatedAtTo,
isAttention,
isSubscription,
isSubscriptionNotViewAdmin,
isDocumentVerification,
isDocumentVerificationNotViewAdmin,
isDocumentW9Form,
documentW9FormStatus,
documentVerificationStatus,
subscriptionTitle,
subscriptionStatus,
userRole,
status,
segmentation,
earningYear,
userId,
page = 1,
limit = 10,
pageFilter,
isCount,
}: {
/**
 * Used to filter data by name
 */
search?: string,
/**
 * Send the _id
 */
city?: string,
/**
 * Send the _id
 */
cities?: Array<string>,
genders?: Array<'male' | 'female'>,
languages?: Array<string>,
hairColors?: Array<string>,
eyeColors?: Array<string>,
dressSizes?: Array<string>,
shoeSizes?: Array<string>,
isOnlyRecommended?: boolean,
isFeatured?: boolean,
isAmbassador?: boolean,
isBoost?: boolean,
/**
 * Send the _id
 */
boostCity?: string,
startAge?: number,
endAge?: number,
heightFrom?: number,
heightTo?: number,
eventPreferences?: 'entertainment' | 'matchmaking' | 'travels',
sort?: 'fullName' | 'earnedSum' | 'createdAt' | 'updatedAt' | 'profileUpdatedAt' | 'boostTopDate' | 'newIdVerification' | 'newMember',
sortDirection?: 1 | -1,
createdAtFrom?: string,
createdAtTo?: string,
profileUpdatedAtFrom?: string,
profileUpdatedAtTo?: string,
/**
 * Get users whose document status is pending.
 */
isAttention?: boolean,
isSubscription?: boolean,
isSubscriptionNotViewAdmin?: boolean,
/**
 * Get users who have started or completed the document verification process (ID Verification)
 */
isDocumentVerification?: boolean,
isDocumentVerificationNotViewAdmin?: boolean,
/**
 * Get users who have uploaded the w9Form document
 */
isDocumentW9Form?: boolean,
documentW9FormStatus?: 'pending' | 'approved' | 'review' | 'declined',
documentVerificationStatus?: 'pending' | 'approved' | 'review' | 'declined',
subscriptionTitle?: 'Free' | 'Elite' | 'Premium',
subscriptionStatus?: 'active' | 'canceled' | 'incomplete' | 'incomplete_expired' | 'past_due' | 'paused' | 'trialing' | 'unpaid',
userRole?: 'client' | 'talent' | 'admin',
status?: 'pending' | 'junk' | 'approved' | 'declined' | 'blocked' | 'deactivated' | 'deleted',
segmentation?: 'all' | 'new' | 'notActive' | 'noBooking' | 'active' | 'Free' | 'Elite' | 'Premium' | 'updated' | 'boosted',
earningYear?: number,
/**
 * Can be used by the admin. Send the _id
 */
userId?: string,
page?: number,
/**
 * If 0 is specified, we will return the data without a limit
 */
limit?: number,
/**
 * Used to filter the received data. We get data where the creation date is less than the specified date. If possible, use this to get the following data instead of the "page" parameter as it works faster. If you are sorting list by "updatedAt" date, you need to use "updatedAt" date for this param
 */
pageFilter?: string,
/**
 * If it actively returns the quantity.
 */
isCount?: boolean,
}): CancelablePromise<PageAdminUserListResponseDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/admin/user/list',
            query: {
                'search': search,
                'city': city,
                'cities': cities,
                'genders': genders,
                'languages': languages,
                'hairColors': hairColors,
                'eyeColors': eyeColors,
                'dressSizes': dressSizes,
                'shoeSizes': shoeSizes,
                'isOnlyRecommended': isOnlyRecommended,
                'isFeatured': isFeatured,
                'isAmbassador': isAmbassador,
                'isBoost': isBoost,
                'boostCity': boostCity,
                'startAge': startAge,
                'endAge': endAge,
                'heightFrom': heightFrom,
                'heightTo': heightTo,
                'eventPreferences': eventPreferences,
                'sort': sort,
                'sortDirection': sortDirection,
                'createdAtFrom': createdAtFrom,
                'createdAtTo': createdAtTo,
                'profileUpdatedAtFrom': profileUpdatedAtFrom,
                'profileUpdatedAtTo': profileUpdatedAtTo,
                'isAttention': isAttention,
                'isSubscription': isSubscription,
                'isSubscriptionNotViewAdmin': isSubscriptionNotViewAdmin,
                'isDocumentVerification': isDocumentVerification,
                'isDocumentVerificationNotViewAdmin': isDocumentVerificationNotViewAdmin,
                'isDocumentW9Form': isDocumentW9Form,
                'documentW9FormStatus': documentW9FormStatus,
                'documentVerificationStatus': documentVerificationStatus,
                'subscriptionTitle': subscriptionTitle,
                'subscriptionStatus': subscriptionStatus,
                'userRole': userRole,
                'status': status,
                'segmentation': segmentation,
                'earningYear': earningYear,
                'userId': userId,
                'page': page,
                'limit': limit,
                'pageFilter': pageFilter,
                'isCount': isCount,
            },
            errors: {
                401: `Invalid authorization data`,
                403: `Only the admin can complete this request.`,
            },
        });
    }

    /**
     * Admin User Count
     * @returns number Admin User Count
     * @throws ApiError
     */
    public getAdminUserCount({
search,
city,
cities,
genders,
languages,
hairColors,
eyeColors,
dressSizes,
shoeSizes,
isOnlyRecommended,
isFeatured,
isAmbassador,
isBoost,
boostCity,
startAge,
endAge,
heightFrom,
heightTo,
eventPreferences,
sort = 'createdAt',
sortDirection = -1,
createdAtFrom,
createdAtTo,
profileUpdatedAtFrom,
profileUpdatedAtTo,
isAttention,
isSubscription,
isSubscriptionNotViewAdmin,
isDocumentVerification,
isDocumentVerificationNotViewAdmin,
isDocumentW9Form,
documentW9FormStatus,
documentVerificationStatus,
subscriptionTitle,
subscriptionStatus,
userRole,
status,
segmentation,
earningYear,
userId,
}: {
/**
 * Used to filter data by name
 */
search?: string,
/**
 * Send the _id
 */
city?: string,
/**
 * Send the _id
 */
cities?: Array<string>,
genders?: Array<'male' | 'female'>,
languages?: Array<string>,
hairColors?: Array<string>,
eyeColors?: Array<string>,
dressSizes?: Array<string>,
shoeSizes?: Array<string>,
isOnlyRecommended?: boolean,
isFeatured?: boolean,
isAmbassador?: boolean,
isBoost?: boolean,
/**
 * Send the _id
 */
boostCity?: string,
startAge?: number,
endAge?: number,
heightFrom?: number,
heightTo?: number,
eventPreferences?: 'entertainment' | 'matchmaking' | 'travels',
sort?: 'fullName' | 'earnedSum' | 'createdAt' | 'updatedAt' | 'profileUpdatedAt' | 'boostTopDate' | 'newIdVerification' | 'newMember',
sortDirection?: 1 | -1,
createdAtFrom?: string,
createdAtTo?: string,
profileUpdatedAtFrom?: string,
profileUpdatedAtTo?: string,
/**
 * Get users whose document status is pending.
 */
isAttention?: boolean,
isSubscription?: boolean,
isSubscriptionNotViewAdmin?: boolean,
/**
 * Get users who have started or completed the document verification process (ID Verification)
 */
isDocumentVerification?: boolean,
isDocumentVerificationNotViewAdmin?: boolean,
/**
 * Get users who have uploaded the w9Form document
 */
isDocumentW9Form?: boolean,
documentW9FormStatus?: 'pending' | 'approved' | 'review' | 'declined',
documentVerificationStatus?: 'pending' | 'approved' | 'review' | 'declined',
subscriptionTitle?: 'Free' | 'Elite' | 'Premium',
subscriptionStatus?: 'active' | 'canceled' | 'incomplete' | 'incomplete_expired' | 'past_due' | 'paused' | 'trialing' | 'unpaid',
userRole?: 'client' | 'talent' | 'admin',
status?: 'pending' | 'junk' | 'approved' | 'declined' | 'blocked' | 'deactivated' | 'deleted',
segmentation?: 'all' | 'new' | 'notActive' | 'noBooking' | 'active' | 'Free' | 'Elite' | 'Premium' | 'updated' | 'boosted',
earningYear?: number,
/**
 * Can be used by the admin. Send the _id
 */
userId?: string,
}): CancelablePromise<number> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/admin/user/count',
            query: {
                'search': search,
                'city': city,
                'cities': cities,
                'genders': genders,
                'languages': languages,
                'hairColors': hairColors,
                'eyeColors': eyeColors,
                'dressSizes': dressSizes,
                'shoeSizes': shoeSizes,
                'isOnlyRecommended': isOnlyRecommended,
                'isFeatured': isFeatured,
                'isAmbassador': isAmbassador,
                'isBoost': isBoost,
                'boostCity': boostCity,
                'startAge': startAge,
                'endAge': endAge,
                'heightFrom': heightFrom,
                'heightTo': heightTo,
                'eventPreferences': eventPreferences,
                'sort': sort,
                'sortDirection': sortDirection,
                'createdAtFrom': createdAtFrom,
                'createdAtTo': createdAtTo,
                'profileUpdatedAtFrom': profileUpdatedAtFrom,
                'profileUpdatedAtTo': profileUpdatedAtTo,
                'isAttention': isAttention,
                'isSubscription': isSubscription,
                'isSubscriptionNotViewAdmin': isSubscriptionNotViewAdmin,
                'isDocumentVerification': isDocumentVerification,
                'isDocumentVerificationNotViewAdmin': isDocumentVerificationNotViewAdmin,
                'isDocumentW9Form': isDocumentW9Form,
                'documentW9FormStatus': documentW9FormStatus,
                'documentVerificationStatus': documentVerificationStatus,
                'subscriptionTitle': subscriptionTitle,
                'subscriptionStatus': subscriptionStatus,
                'userRole': userRole,
                'status': status,
                'segmentation': segmentation,
                'earningYear': earningYear,
                'userId': userId,
            },
            errors: {
                401: `Invalid authorization data`,
                403: `Only the admin can complete this request.`,
            },
        });
    }

    /**
     * User CSV
     * @returns any User CSV file
     * @throws ApiError
     */
    public exportUserCsv({
search,
city,
cities,
genders,
languages,
hairColors,
eyeColors,
dressSizes,
shoeSizes,
isOnlyRecommended,
isFeatured,
isAmbassador,
isBoost,
boostCity,
startAge,
endAge,
heightFrom,
heightTo,
eventPreferences,
sort = 'createdAt',
sortDirection = -1,
createdAtFrom,
createdAtTo,
profileUpdatedAtFrom,
profileUpdatedAtTo,
isAttention,
isSubscription,
isSubscriptionNotViewAdmin,
isDocumentVerification,
isDocumentVerificationNotViewAdmin,
isDocumentW9Form,
documentW9FormStatus,
documentVerificationStatus,
subscriptionTitle,
subscriptionStatus,
userRole,
status,
segmentation,
earningYear,
userId,
}: {
/**
 * Used to filter data by name
 */
search?: string,
/**
 * Send the _id
 */
city?: string,
/**
 * Send the _id
 */
cities?: Array<string>,
genders?: Array<'male' | 'female'>,
languages?: Array<string>,
hairColors?: Array<string>,
eyeColors?: Array<string>,
dressSizes?: Array<string>,
shoeSizes?: Array<string>,
isOnlyRecommended?: boolean,
isFeatured?: boolean,
isAmbassador?: boolean,
isBoost?: boolean,
/**
 * Send the _id
 */
boostCity?: string,
startAge?: number,
endAge?: number,
heightFrom?: number,
heightTo?: number,
eventPreferences?: 'entertainment' | 'matchmaking' | 'travels',
sort?: 'fullName' | 'earnedSum' | 'createdAt' | 'updatedAt' | 'profileUpdatedAt' | 'boostTopDate' | 'newIdVerification' | 'newMember',
sortDirection?: 1 | -1,
createdAtFrom?: string,
createdAtTo?: string,
profileUpdatedAtFrom?: string,
profileUpdatedAtTo?: string,
/**
 * Get users whose document status is pending.
 */
isAttention?: boolean,
isSubscription?: boolean,
isSubscriptionNotViewAdmin?: boolean,
/**
 * Get users who have started or completed the document verification process (ID Verification)
 */
isDocumentVerification?: boolean,
isDocumentVerificationNotViewAdmin?: boolean,
/**
 * Get users who have uploaded the w9Form document
 */
isDocumentW9Form?: boolean,
documentW9FormStatus?: 'pending' | 'approved' | 'review' | 'declined',
documentVerificationStatus?: 'pending' | 'approved' | 'review' | 'declined',
subscriptionTitle?: 'Free' | 'Elite' | 'Premium',
subscriptionStatus?: 'active' | 'canceled' | 'incomplete' | 'incomplete_expired' | 'past_due' | 'paused' | 'trialing' | 'unpaid',
userRole?: 'client' | 'talent' | 'admin',
status?: 'pending' | 'junk' | 'approved' | 'declined' | 'blocked' | 'deactivated' | 'deleted',
segmentation?: 'all' | 'new' | 'notActive' | 'noBooking' | 'active' | 'Free' | 'Elite' | 'Premium' | 'updated' | 'boosted',
earningYear?: number,
/**
 * Can be used by the admin. Send the _id
 */
userId?: string,
}): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/admin/user/export',
            query: {
                'search': search,
                'city': city,
                'cities': cities,
                'genders': genders,
                'languages': languages,
                'hairColors': hairColors,
                'eyeColors': eyeColors,
                'dressSizes': dressSizes,
                'shoeSizes': shoeSizes,
                'isOnlyRecommended': isOnlyRecommended,
                'isFeatured': isFeatured,
                'isAmbassador': isAmbassador,
                'isBoost': isBoost,
                'boostCity': boostCity,
                'startAge': startAge,
                'endAge': endAge,
                'heightFrom': heightFrom,
                'heightTo': heightTo,
                'eventPreferences': eventPreferences,
                'sort': sort,
                'sortDirection': sortDirection,
                'createdAtFrom': createdAtFrom,
                'createdAtTo': createdAtTo,
                'profileUpdatedAtFrom': profileUpdatedAtFrom,
                'profileUpdatedAtTo': profileUpdatedAtTo,
                'isAttention': isAttention,
                'isSubscription': isSubscription,
                'isSubscriptionNotViewAdmin': isSubscriptionNotViewAdmin,
                'isDocumentVerification': isDocumentVerification,
                'isDocumentVerificationNotViewAdmin': isDocumentVerificationNotViewAdmin,
                'isDocumentW9Form': isDocumentW9Form,
                'documentW9FormStatus': documentW9FormStatus,
                'documentVerificationStatus': documentVerificationStatus,
                'subscriptionTitle': subscriptionTitle,
                'subscriptionStatus': subscriptionStatus,
                'userRole': userRole,
                'status': status,
                'segmentation': segmentation,
                'earningYear': earningYear,
                'userId': userId,
            },
            errors: {
                401: `Invalid authorization data`,
                403: `Only an admin with isSuperAdmin rights can add a new administrator.`,
            },
        });
    }

}
