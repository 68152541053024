/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AdminCreateTransactionDTO } from '../models/AdminCreateTransactionDTO';
import type { PageTransactionResponseDTO } from '../models/PageTransactionResponseDTO';
import type { TransactionResponseDTO } from '../models/TransactionResponseDTO';
import type { TransactionStatsResponseDTO } from '../models/TransactionStatsResponseDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class TransactionService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Admin Create Transaction
     * @returns any Transaction Created
     * @throws ApiError
     */
    public adminCreateTransaction({
requestBody,
}: {
requestBody: AdminCreateTransactionDTO,
}): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/transaction/admin-create',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Invalid authorization data`,
                403: `Only an admin with the right isSuperAdmin or transaction.addTransaction can execute the request.`,
            },
        });
    }

    /**
     * Transaction list
     * @returns PageTransactionResponseDTO Transaction list
     * @throws ApiError
     */
    public getTransactionList({
createdAtFrom,
createdAtTo,
types,
categories,
movementFunds,
payoutType,
status,
userRole,
city,
userId,
userSourceId,
bookingId,
charityId,
page = 1,
limit = 10,
pageFilter,
isCount,
}: {
createdAtFrom?: string,
createdAtTo?: string,
types?: Array<'payment' | 'payout' | 'membership' | 'referral' | 'refund'>,
categories?: Array<'createBooking' | 'bookingPaymentTalent' | 'bookingUpdatePaymentTalents' | 'bookingPaymentParticipation' | 'balanceReplenishment' | 'bookingApproveCancel' | 'bookingPaidTalent' | 'bookingCharityDonation' | 'bookingPaidParticipation' | 'referralTalent' | 'referralClient' | 'membershipPayment' | 'cashOut'>,
movementFunds?: 'replenishment' | 'write-downs',
payoutType?: 'stripe' | 'paypal',
status?: 'pending' | 'succeeded' | 'canceled' | 'failed',
/**
 * Can be used by the admin.
 */
userRole?: 'client' | 'talent' | 'admin',
/**
 * Send the _id
 */
city?: string,
/**
 * Can be used by the admin. Send the _id
 */
userId?: string,
/**
 * Send the _id
 */
userSourceId?: string,
/**
 * Can be used by the admin. Send the _id
 */
bookingId?: string,
/**
 * Can be used by the admin. Send the _id
 */
charityId?: string,
page?: number,
/**
 * If 0 is specified, we will return the data without a limit
 */
limit?: number,
/**
 * Used to filter the received data. We get data where the creation date is less than the specified date. If possible, use this to get the following data instead of the "page" parameter as it works faster.
 */
pageFilter?: string,
/**
 * If it actively returns the quantity.
 */
isCount?: boolean,
}): CancelablePromise<PageTransactionResponseDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/transaction/list',
            query: {
                'createdAtFrom': createdAtFrom,
                'createdAtTo': createdAtTo,
                'types': types,
                'categories': categories,
                'movementFunds': movementFunds,
                'payoutType': payoutType,
                'status': status,
                'userRole': userRole,
                'city': city,
                'userId': userId,
                'userSourceId': userSourceId,
                'bookingId': bookingId,
                'charityId': charityId,
                'page': page,
                'limit': limit,
                'pageFilter': pageFilter,
                'isCount': isCount,
            },
            errors: {
                401: `Invalid authorization data`,
            },
        });
    }

    /**
     * Transaction CSV
     * @returns any Transaction CSV file
     * @throws ApiError
     */
    public exportTransactionCsv({
createdAtFrom,
createdAtTo,
types,
categories,
movementFunds,
payoutType,
status,
userRole,
city,
userId,
userSourceId,
bookingId,
charityId,
}: {
createdAtFrom?: string,
createdAtTo?: string,
types?: Array<'payment' | 'payout' | 'membership' | 'referral' | 'refund'>,
categories?: Array<'createBooking' | 'bookingPaymentTalent' | 'bookingUpdatePaymentTalents' | 'bookingPaymentParticipation' | 'balanceReplenishment' | 'bookingApproveCancel' | 'bookingPaidTalent' | 'bookingCharityDonation' | 'bookingPaidParticipation' | 'referralTalent' | 'referralClient' | 'membershipPayment' | 'cashOut'>,
movementFunds?: 'replenishment' | 'write-downs',
payoutType?: 'stripe' | 'paypal',
status?: 'pending' | 'succeeded' | 'canceled' | 'failed',
/**
 * Can be used by the admin.
 */
userRole?: 'client' | 'talent' | 'admin',
/**
 * Send the _id
 */
city?: string,
/**
 * Can be used by the admin. Send the _id
 */
userId?: string,
/**
 * Send the _id
 */
userSourceId?: string,
/**
 * Can be used by the admin. Send the _id
 */
bookingId?: string,
/**
 * Can be used by the admin. Send the _id
 */
charityId?: string,
}): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/transaction/export',
            query: {
                'createdAtFrom': createdAtFrom,
                'createdAtTo': createdAtTo,
                'types': types,
                'categories': categories,
                'movementFunds': movementFunds,
                'payoutType': payoutType,
                'status': status,
                'userRole': userRole,
                'city': city,
                'userId': userId,
                'userSourceId': userSourceId,
                'bookingId': bookingId,
                'charityId': charityId,
            },
            errors: {
                401: `Invalid authorization data`,
                403: `Only an admin with the right isSuperAdmin or transaction.downloadCSV can execute the request.`,
            },
        });
    }

    /**
     * Transaction Count
     * @returns number Transaction Count
     * @throws ApiError
     */
    public getTransactionCount({
createdAtFrom,
createdAtTo,
types,
categories,
movementFunds,
payoutType,
status,
userRole,
city,
userId,
userSourceId,
bookingId,
charityId,
}: {
createdAtFrom?: string,
createdAtTo?: string,
types?: Array<'payment' | 'payout' | 'membership' | 'referral' | 'refund'>,
categories?: Array<'createBooking' | 'bookingPaymentTalent' | 'bookingUpdatePaymentTalents' | 'bookingPaymentParticipation' | 'balanceReplenishment' | 'bookingApproveCancel' | 'bookingPaidTalent' | 'bookingCharityDonation' | 'bookingPaidParticipation' | 'referralTalent' | 'referralClient' | 'membershipPayment' | 'cashOut'>,
movementFunds?: 'replenishment' | 'write-downs',
payoutType?: 'stripe' | 'paypal',
status?: 'pending' | 'succeeded' | 'canceled' | 'failed',
/**
 * Can be used by the admin.
 */
userRole?: 'client' | 'talent' | 'admin',
/**
 * Send the _id
 */
city?: string,
/**
 * Can be used by the admin. Send the _id
 */
userId?: string,
/**
 * Send the _id
 */
userSourceId?: string,
/**
 * Can be used by the admin. Send the _id
 */
bookingId?: string,
/**
 * Can be used by the admin. Send the _id
 */
charityId?: string,
}): CancelablePromise<number> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/transaction/count',
            query: {
                'createdAtFrom': createdAtFrom,
                'createdAtTo': createdAtTo,
                'types': types,
                'categories': categories,
                'movementFunds': movementFunds,
                'payoutType': payoutType,
                'status': status,
                'userRole': userRole,
                'city': city,
                'userId': userId,
                'userSourceId': userSourceId,
                'bookingId': bookingId,
                'charityId': charityId,
            },
            errors: {
                401: `Invalid authorization data`,
            },
        });
    }

    /**
     * Transaction Stats
     * @returns TransactionStatsResponseDTO Transaction Stats
     * @throws ApiError
     */
    public getTransactionStats({
createdAtFrom,
createdAtTo,
types,
categories,
movementFunds,
payoutType,
status,
userRole,
city,
userId,
userSourceId,
bookingId,
charityId,
}: {
createdAtFrom?: string,
createdAtTo?: string,
types?: Array<'payment' | 'payout' | 'membership' | 'referral' | 'refund'>,
categories?: Array<'createBooking' | 'bookingPaymentTalent' | 'bookingUpdatePaymentTalents' | 'bookingPaymentParticipation' | 'balanceReplenishment' | 'bookingApproveCancel' | 'bookingPaidTalent' | 'bookingCharityDonation' | 'bookingPaidParticipation' | 'referralTalent' | 'referralClient' | 'membershipPayment' | 'cashOut'>,
movementFunds?: 'replenishment' | 'write-downs',
payoutType?: 'stripe' | 'paypal',
status?: 'pending' | 'succeeded' | 'canceled' | 'failed',
/**
 * Can be used by the admin.
 */
userRole?: 'client' | 'talent' | 'admin',
/**
 * Send the _id
 */
city?: string,
/**
 * Can be used by the admin. Send the _id
 */
userId?: string,
/**
 * Send the _id
 */
userSourceId?: string,
/**
 * Can be used by the admin. Send the _id
 */
bookingId?: string,
/**
 * Can be used by the admin. Send the _id
 */
charityId?: string,
}): CancelablePromise<TransactionStatsResponseDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/transaction/stats',
            query: {
                'createdAtFrom': createdAtFrom,
                'createdAtTo': createdAtTo,
                'types': types,
                'categories': categories,
                'movementFunds': movementFunds,
                'payoutType': payoutType,
                'status': status,
                'userRole': userRole,
                'city': city,
                'userId': userId,
                'userSourceId': userSourceId,
                'bookingId': bookingId,
                'charityId': charityId,
            },
            errors: {
                401: `Invalid authorization data`,
            },
        });
    }

    /**
     * Get Transaction
     * @returns TransactionResponseDTO Transaction
     * @throws ApiError
     */
    public getTransaction({
id,
}: {
/**
 * Id
 */
id: string,
}): CancelablePromise<TransactionResponseDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/transaction/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Invalid authorization data`,
            },
        });
    }

}
