import { allItem } from "src/common/constants/select";
import Context from "./context";
import { SelectItem } from "src/common/types/ui";
import { CityResponseDTO } from "src/api/openapi";

export type CityStateValue = SelectItem<string> & {
  detail?: Omit<CityResponseDTO, "image"> &
    Partial<Pick<CityResponseDTO, "image">>;
};

export interface CityState {
  isGetData: boolean;
  count: number;
  page: number;
  cityItems: CityStateValue[];
  value: CityStateValue;
}

export const initialCityState: CityState = {
  isGetData: false,
  count: 0,
  page: 1,
  cityItems: [],
  value: allItem,
};

const CityContext = new Context(initialCityState);

export default CityContext;
