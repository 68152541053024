import React, { FC, forwardRef, SyntheticEvent, useEffect } from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import SnackbarContext, {
  initialSnackbarState,
} from "../store/contexts/snackbar-context";

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CustomSnackbar: FC = () => {
  const { data, setData } = SnackbarContext.useContext();

  const handleClose = (event?: SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    setData({ open: false });
  };

  useEffect(() => {
    if (!data.open) setData(initialSnackbarState);
  }, [data.open]);

  return (
    <Snackbar
      open={data.open}
      autoHideDuration={data.duration}
      anchorOrigin={{
        vertical: data.vertical,
        horizontal: data.horizontal,
      }}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity={data.type} sx={{ width: "100%" }}>
        {data.message}
      </Alert>
    </Snackbar>
  );
};

export default CustomSnackbar;