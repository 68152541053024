/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreatePushNotificationDTO } from '../models/CreatePushNotificationDTO';
import type { PagePushNotificationListResponseDTO } from '../models/PagePushNotificationListResponseDTO';
import type { PushNotificationResponseDTO } from '../models/PushNotificationResponseDTO';
import type { UpdatePushNotificationDTO } from '../models/UpdatePushNotificationDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PushNotificationsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Push notification creation
     * @returns any Push notification successfully created
     * @throws ApiError
     */
    public createPushNotification({
requestBody,
}: {
requestBody: CreatePushNotificationDTO,
}): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/push-notification',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Wrong authorization data`,
                403: `Only an admin with the right isSuperAdmin or marketing.accessPushNotifications can execute the request.`,
            },
        });
    }

    /**
     * Update push notification
     * @returns any Push notification successfully updated
     * @throws ApiError
     */
    public updatePushNotification({
id,
requestBody,
}: {
/**
 * Id
 */
id: string,
requestBody: UpdatePushNotificationDTO,
}): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/push-notification/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Wrong authorization data`,
                403: `Only an admin with the right isSuperAdmin or marketing.accessPushNotifications can execute the request.`,
                404: `Push notification not found`,
            },
        });
    }

    /**
     * Delete push notification
     * @returns any Push notification successfully deleted
     * @throws ApiError
     */
    public deletePushNotification({
id,
}: {
/**
 * Id
 */
id: string,
}): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/push-notification/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Wrong authorization data`,
                403: `Only an admin with the right isSuperAdmin or isDelete can execute the request.`,
                404: `Push notification not found`,
            },
        });
    }

    /**
     * Push notification
     * @returns PushNotificationResponseDTO Push notification
     * @throws ApiError
     */
    public getPushNotification({
id,
}: {
/**
 * Id
 */
id: string,
}): CancelablePromise<PushNotificationResponseDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/push-notification/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Wrong authorization data`,
                403: `Only an admin with the right isSuperAdmin or marketing.accessPushNotifications can execute the request.`,
            },
        });
    }

    /**
     * Push notification list
     * @returns PagePushNotificationListResponseDTO List of push notifications
     * @throws ApiError
     */
    public getPushNotificationList({
search,
userRole,
segmentation,
cities,
page = 1,
limit = 10,
pageFilter,
isCount,
}: {
/**
 * Used to filter data by title
 */
search?: string,
userRole?: 'client' | 'talent' | 'admin',
segmentation?: 'all' | 'new' | 'notActive' | 'noBooking' | 'active' | 'Free' | 'Elite' | 'Premium' | 'updated' | 'boosted',
/**
 * Send the _id
 */
cities?: Array<string>,
page?: number,
/**
 * If 0 is specified, we will return the data without a limit
 */
limit?: number,
/**
 * Used to filter the received data. We get data where the sendDate is less than the specified date. If possible, use this to get the following data instead of the "page" parameter as it works faster.
 */
pageFilter?: string,
/**
 * If it actively returns the quantity.
 */
isCount?: boolean,
}): CancelablePromise<PagePushNotificationListResponseDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/push-notification/list',
            query: {
                'search': search,
                'userRole': userRole,
                'segmentation': segmentation,
                'cities': cities,
                'page': page,
                'limit': limit,
                'pageFilter': pageFilter,
                'isCount': isCount,
            },
            errors: {
                401: `Wrong authorization data`,
                403: `Only an admin with the right isSuperAdmin or marketing.accessPushNotifications can execute the request.`,
            },
        });
    }

    /**
     * PushNotification Count
     * @returns number PushNotification Count
     * @throws ApiError
     */
    public getPushNotificationCount({
search,
userRole,
segmentation,
cities,
}: {
/**
 * Used to filter data by title
 */
search?: string,
userRole?: 'client' | 'talent' | 'admin',
segmentation?: 'all' | 'new' | 'notActive' | 'noBooking' | 'active' | 'Free' | 'Elite' | 'Premium' | 'updated' | 'boosted',
/**
 * Send the _id
 */
cities?: Array<string>,
}): CancelablePromise<number> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/push-notification/count',
            query: {
                'search': search,
                'userRole': userRole,
                'segmentation': segmentation,
                'cities': cities,
            },
            errors: {
                401: `Wrong authorization data`,
                403: `Only an admin with the right isSuperAdmin or marketing.accessPushNotifications can execute the request.`,
            },
        });
    }

}
