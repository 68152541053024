/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BookingConfirmPaymentClientDTO } from '../models/BookingConfirmPaymentClientDTO';
import type { BookingConfirmPaymentTalentDTO } from '../models/BookingConfirmPaymentTalentDTO';
import type { BookingCreatePaymentDTO } from '../models/BookingCreatePaymentDTO';
import type { BookingPaymentUpdateRateTalentsDTO } from '../models/BookingPaymentUpdateRateTalentsDTO';
import type { PaymentIntentResponseDTO } from '../models/PaymentIntentResponseDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PaymentService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Create booking payment
     * @returns PaymentIntentResponseDTO Use clientSecret to confirm the payment.
     * @throws ApiError
     */
    public createBookingPayment({
requestBody,
}: {
requestBody: BookingCreatePaymentDTO,
}): CancelablePromise<PaymentIntentResponseDTO> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/payment/booking',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Invalid authorization data`,
                403: `Only a user with a client role can payment create a Booking.`,
            },
        });
    }

    /**
     * Booking Confirm Payment Talent
     * @returns PaymentIntentResponseDTO Use clientSecret to confirm the payment.
     * @throws ApiError
     */
    public bookingConfirmPaymentTalent({
requestBody,
}: {
requestBody: BookingConfirmPaymentTalentDTO,
}): CancelablePromise<PaymentIntentResponseDTO> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/payment/booking/confirm-talent',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Only the owner of the reservation can make the payment. There is no such talent in Booking. Booking user status not waitingPayment. The maximum number of confirmed participants has been reached.`,
                401: `Invalid authorization data`,
            },
        });
    }

    /**
     * Booking Payment Update Rate Talents
     * @returns PaymentIntentResponseDTO Use clientSecret to confirm the payment.
     * @throws ApiError
     */
    public bookingPaymentUpdateRateTalents({
requestBody,
}: {
requestBody: BookingPaymentUpdateRateTalentsDTO,
}): CancelablePromise<PaymentIntentResponseDTO> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/payment/booking/update-rate-talents',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Only the owner of the reservation can make the payment.`,
                401: `Invalid authorization data`,
            },
        });
    }

    /**
     * Booking Confirm Payment Client
     * @returns PaymentIntentResponseDTO Use clientSecret to confirm the payment.
     * @throws ApiError
     */
    public bookingConfirmPaymentClient({
requestBody,
}: {
requestBody: BookingConfirmPaymentClientDTO,
}): CancelablePromise<PaymentIntentResponseDTO> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/payment/booking/confirm-client',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `There is no such client in Booking. Booking user status not pending. The maximum number of confirmed participants has been reached.`,
                401: `Invalid authorization data`,
            },
        });
    }

}
