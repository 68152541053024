/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UpdateCommonDataDTO = {
    name?: string;
    status?: UpdateCommonDataDTO.status;
};

export namespace UpdateCommonDataDTO {

    export enum status {
        ACTIVE = 'active',
        HIDDEN = 'hidden',
    }


}
