/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CityPopulateResponseDTO } from './CityPopulateResponseDTO';
import type { UserDocumentsPublicResponseDTO } from './UserDocumentsPublicResponseDTO';

export type UserListResponseDTO = {
    _id: string;
    firstName: string;
    lastName: string;
    city: CityPopulateResponseDTO;
    avatar: string;
    rating: number;
    role: UserListResponseDTO.role;
    status: UserListResponseDTO.status;
    /**
     * There is a role client
     */
    subscriptionTitle?: string;
    isFeatured: boolean;
    isLike?: boolean;
    isRecommended?: boolean;
    profileUpdatedAt?: string;
    documents?: UserDocumentsPublicResponseDTO;
    createdAt: string;
    updatedAt: string;
};

export namespace UserListResponseDTO {

    export enum role {
        CLIENT = 'client',
        TALENT = 'talent',
        ADMIN = 'admin',
    }

    export enum status {
        PENDING = 'pending',
        JUNK = 'junk',
        APPROVED = 'approved',
        DECLINED = 'declined',
        BLOCKED = 'blocked',
        DEACTIVATED = 'deactivated',
        DELETED = 'deleted',
    }


}
