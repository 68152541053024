/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { VenueBookingInstructionsDTO } from './VenueBookingInstructionsDTO';
import type { VenueHoursOperationsDTO } from './VenueHoursOperationsDTO';
import type { VenueMinDataDTO } from './VenueMinDataDTO';

export type CreateAdminVenueDTO = {
    name: string;
    phone?: string;
    email?: string;
    /**
     * If type != travel is required.
     */
    address?: string;
    /**
     * If type != travel is required. Send the _id
     */
    city?: string;
    /**
     * If type != travel is required.
     */
    lat?: number;
    /**
     * If type != travel is required.
     */
    long?: number;
    description?: string;
    /**
     * If type != travel is required.
     */
    priceRating?: CreateAdminVenueDTO.priceRating;
    /**
     * Send the name of the value instead of the _id. If type != ${VenueExperienceType.TRAVEL} is required.
     */
    type?: string;
    experienceType: CreateAdminVenueDTO.experienceType;
    status: CreateAdminVenueDTO.status;
    isPopular?: boolean;
    /**
     * Send the name of the value instead of the _id. If type != travel is required.
     */
    kitchens?: Array<string>;
    /**
     * Send the name of the value instead of the _id. If type != travel is required.
     */
    diningStyles?: Array<string>;
    /**
     * Send the name of the value instead of the _id. If type != travel is required.
     */
    dressCodes?: Array<string>;
    /**
     * Users who will be able to see this entry. Send the _id
     */
    onlyUsers: Array<string>;
    /**
     * If type != travel is required.
     */
    hoursOperations?: VenueHoursOperationsDTO;
    venueMinData: VenueMinDataDTO;
    bookingInstructions: VenueBookingInstructionsDTO;
    uploadPhotos?: Array<Blob>;
    uploadLogo?: Blob;
};

export namespace CreateAdminVenueDTO {

    /**
     * If type != travel is required.
     */
    export enum priceRating {
        LOW = 'low',
        MEDIUM = 'medium',
        HIGH = 'high',
        VERY_HIGH = 'veryHigh',
    }

    export enum experienceType {
        EVENT = 'event',
        TRAVEL = 'travel',
    }

    export enum status {
        PENDING = 'pending',
        ACTIVE = 'active',
        HIDDEN = 'hidden',
        DELETED = 'deleted',
    }


}
