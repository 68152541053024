/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { UserListResponseDTO } from './UserListResponseDTO';

export type BookingChatRoomMessageResponseDTO = {
    _id: string;
    owner?: UserListResponseDTO;
    room: string;
    reads: Array<string>;
    text: string;
    filePath?: string;
    fileOriginalname?: string;
    fileType?: BookingChatRoomMessageResponseDTO.fileType;
    isSystem: boolean;
    createdAt: string;
    updatedAt: string;
};

export namespace BookingChatRoomMessageResponseDTO {

    export enum fileType {
        IMAGE = 'image',
        DOCUMENT = 'document',
    }


}
