const config = {
  baseUrl: process.env.REACT_APP_BASE_URL || "",
  apiUrl: process.env.REACT_APP_API_URL || "",
  wsUrl: process.env.REACT_APP_WS_URL || "",
  instagramUrl:
    process.env.REACT_APP_INSTAGRAM_URL || "https://www.instagram.com",
  idVerificationUrl:
    process.env.REACT_APP_ID_VERIFICATION_URL ||
    "https://app.withpersona.com/dashboard/inquiries",
};

export default config;
