/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PageLikeResponseDTO } from '../models/PageLikeResponseDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class LikeService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Like List
     * @returns PageLikeResponseDTO Like List
     * @throws ApiError
     */
    public getLikeList({
userId,
resourceType,
page = 1,
limit = 10,
pageFilter,
isCount,
}: {
/**
 * Can be used by the admin. Send the _id
 */
userId?: string,
resourceType?: 'User' | 'Venue' | 'Booking',
page?: number,
/**
 * If 0 is specified, we will return the data without a limit
 */
limit?: number,
/**
 * Used to filter the received data. We get data where the creation date is less than the specified date. If possible, use this to get the following data instead of the "page" parameter as it works faster.
 */
pageFilter?: string,
/**
 * If it actively returns the quantity.
 */
isCount?: boolean,
}): CancelablePromise<PageLikeResponseDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/like/list',
            query: {
                'userId': userId,
                'resourceType': resourceType,
                'page': page,
                'limit': limit,
                'pageFilter': pageFilter,
                'isCount': isCount,
            },
            errors: {
                401: `Invalid authorization data`,
            },
        });
    }

    /**
     * Like Count
     * @returns number Like Count
     * @throws ApiError
     */
    public getLikeCount({
userId,
resourceType,
}: {
/**
 * Can be used by the admin. Send the _id
 */
userId?: string,
resourceType?: 'User' | 'Venue' | 'Booking',
}): CancelablePromise<number> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/like/count',
            query: {
                'userId': userId,
                'resourceType': resourceType,
            },
            errors: {
                401: `Invalid authorization data`,
            },
        });
    }

}
