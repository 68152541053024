import moment from "moment";
import "moment-timezone";

export const newDate = (data?: moment.MomentInput): moment.Moment =>
  moment(data);

export const getDateMonthDayYear = (
  date: moment.MomentInput,
  timezone = "",
  format?: string
): string => {
  if (timezone) return moment.tz(date, timezone).format(format || "MM.DD.YY");
  return moment(date).format(format || "MM.DD.YY");
};

export const getDatePeriod = (
  date1: moment.MomentInput,
  date2: moment.MomentInput,
  separator = "-",
  parseZone?: boolean
): string => {
  const start = parseZone ? moment(date1).parseZone() : moment(date1);
  const end = parseZone ? moment(date2).parseZone() : moment(date2);
  const isEqualMonth = start.month() === end.month();
  const isEqualYear = start.year() === end.year();
  const startFormat = `D${!isEqualMonth ? " MMMM" : ""}${
    !isEqualYear ? ", YYYY" : ""
  }`;
  if (startFormat === "D")
    return end.format(`MMMM ${start.format(startFormat)}${separator}D, YYYY`);

  return `${start.format(startFormat)} ${separator} ${end.format(
    "D MMMM, YYYY"
  )}`;
};

export const getDatePeriodTime = (
  date1: moment.MomentInput,
  date2: moment.MomentInput,
  separator = "-",
  parseZone?: boolean
): string => {
  const start = parseZone ? moment(date1).parseZone() : moment(date1);
  const end = parseZone ? moment(date2).parseZone() : moment(date2);

  return `${start.format("h:mA")} ${separator} ${end.format("h:mA")}`;
};

export const getDatePeriodTimeTimezone = (
  date1: moment.MomentInput,
  date2: moment.MomentInput,
  separator = "-",
  timezone = ""
): string => {
  const start = moment.tz(date1, timezone);
  const end = moment.tz(date2, timezone);

  return `${start.format("h:mmA")} ${separator} ${end.format("h:mmA")}`;
};

export const getDateMonthDayFullYear = (
  date: moment.MomentInput,
  parseZone?: boolean,
  format?: string
): string => {
  if (parseZone)
    return moment(date)
      .parseZone()
      .format(format || "MM.DD.YYYY");
  return moment(date).format(format || "MM.DD.YYYY");
};

export const getFullTimeDate = (
  date: moment.MomentInput,
  timezone = ""
): string => {
  if (timezone) return moment.tz(date, timezone).format("LT, MMM DD, YYYY");
  return moment(date).format("LT, MMM DD, YYYY");
};

export const getDate = (date: moment.MomentInput, timezone = ""): string => {
  if (timezone) return moment.tz(date, timezone).format("MMMM D, YYYY");
  return moment(date).format("MMMM D, YYYY");
};

export const getFullDate = (
  date: moment.MomentInput,
  timezone = ""
): string => {
  if (timezone) return moment.tz(date, timezone).format("ddd, MMMM DD, YYYY");
  return moment(date).format("ddd, MMMM DD, YYYY");
};

export const getTime = (date: moment.MomentInput, timezone = ""): string => {
  if (timezone) return moment.tz(date, timezone).format("LT");
  return moment(date).format("LT");
};

export const getTimeZone = (timezone: string): string =>
  moment.tz(timezone).format("Z");

export const addDate = (
  date: moment.Moment,
  amount: number,
  unit: moment.DurationInputArg2
) => date.add(amount, unit);

export const subtractDate = (
  date: moment.Moment,
  amount: number,
  unit: moment.DurationInputArg2
) => date.subtract(amount, unit);

export const getAge = (birthDate: moment.MomentInput, accuracy = false) =>
  moment().diff(birthDate, "years", accuracy);
