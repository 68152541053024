import React, { useEffect, useState, lazy } from "react";
import Loader from "../components/UI/Loader";
import appClient from "src/api/appClient";
import UserContext from "src/components/store/contexts/user-context";
import { logoutHook } from "src/common/hooks/userHook";

const PrivateRouterLayout = lazy(() => import("./PrivateRouterLayout"));

const PrivateRouter = () => {
  const { setData: setUser } = UserContext.useContext();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { logout } = logoutHook();

  useEffect(() => {
    setIsLoading(true);
    appClient.auth
      .getUserInfo()
      .then((data) => {
        setUser(data);
      })
      .catch(() => {
        logout();
      })
      .finally(() => setIsLoading(false));
  }, []);

  if (isLoading) return <Loader isMinHeight />;

  return <PrivateRouterLayout />;
};

export default PrivateRouter;
