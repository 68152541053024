/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CheckReferralCodeDTO } from '../models/CheckReferralCodeDTO';
import type { CheckReferralCodeResponseDTO } from '../models/CheckReferralCodeResponseDTO';
import type { PageReferralResponseDTO } from '../models/PageReferralResponseDTO';
import type { PageReferralTransactionResponseDTO } from '../models/PageReferralTransactionResponseDTO';
import type { ReferralResponseDTO } from '../models/ReferralResponseDTO';
import type { SetReferralDTO } from '../models/SetReferralDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ReferralService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Set Referral
     * @returns any 
     * @throws ApiError
     */
    public setReferral({
requestBody,
}: {
requestBody: SetReferralDTO,
}): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/referral',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Invalid authorization data`,
                403: `Only an administrator with isSuperAdmin rights can use this request.`,
            },
        });
    }

    /**
     * Referral User List
     * @returns PageReferralResponseDTO List of referrals
     * @throws ApiError
     */
    public referralList({
userRole,
userId,
page = 1,
limit = 10,
pageFilter,
isCount,
}: {
userRole?: 'client' | 'talent',
/**
 * Can only be used by the admin to get the user's referral data.  If not specified, we will get referrals of the current user. Send the _id
 */
userId?: string,
page?: number,
/**
 * If 0 is specified, we will return the data without a limit
 */
limit?: number,
/**
 * Used to filter the received data. We get data where the creation date is less than the specified date. If possible, use this to get the following data instead of the "page" parameter as it works faster.
 */
pageFilter?: string,
/**
 * If it actively returns the quantity.
 */
isCount?: boolean,
}): CancelablePromise<PageReferralResponseDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/referral/list',
            query: {
                'userRole': userRole,
                'userId': userId,
                'page': page,
                'limit': limit,
                'pageFilter': pageFilter,
                'isCount': isCount,
            },
            errors: {
                401: `Invalid authorization data`,
            },
        });
    }

    /**
     * Referral Count
     * @returns number Referral Count
     * @throws ApiError
     */
    public getReferralCount({
userRole,
userId,
}: {
userRole?: 'client' | 'talent',
/**
 * Can only be used by the admin to get the user's referral data.  If not specified, we will get referrals of the current user. Send the _id
 */
userId?: string,
}): CancelablePromise<number> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/referral/count',
            query: {
                'userRole': userRole,
                'userId': userId,
            },
            errors: {
                401: `Invalid authorization data`,
            },
        });
    }

    /**
     * Check Referral Code
     * @returns CheckReferralCodeResponseDTO Is referral code busy
     * @throws ApiError
     */
    public checkReferralCode({
requestBody,
}: {
requestBody: CheckReferralCodeDTO,
}): CancelablePromise<CheckReferralCodeResponseDTO> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/referral/code/check',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Invalid authorization data`,
            },
        });
    }

    /**
     * Get Referral Data
     * @returns ReferralResponseDTO Referral Data
     * @throws ApiError
     */
    public getReferral({
id,
}: {
/**
 * Id
 */
id: string,
}): CancelablePromise<ReferralResponseDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/referral/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Invalid authorization data`,
            },
        });
    }

    /**
     * Referral User Transaction List
     * @returns PageReferralTransactionResponseDTO List of transaction referral
     * @throws ApiError
     */
    public getReferralTransactionList({
createdAtFrom,
createdAtTo,
city,
userId,
userSourceId,
page = 1,
limit = 10,
pageFilter,
isCount,
}: {
createdAtFrom?: string,
createdAtTo?: string,
/**
 * Send the _id
 */
city?: string,
/**
 * Can be used by the admin. Send the _id
 */
userId?: string,
/**
 * Send the referral._id
 */
userSourceId?: string,
page?: number,
/**
 * If 0 is specified, we will return the data without a limit
 */
limit?: number,
/**
 * Used to filter the received data. We get data where the creation date is less than the specified date. If possible, use this to get the following data instead of the "page" parameter as it works faster.
 */
pageFilter?: string,
/**
 * If it actively returns the quantity.
 */
isCount?: boolean,
}): CancelablePromise<PageReferralTransactionResponseDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/referral/transaction/list',
            query: {
                'createdAtFrom': createdAtFrom,
                'createdAtTo': createdAtTo,
                'city': city,
                'userId': userId,
                'userSourceId': userSourceId,
                'page': page,
                'limit': limit,
                'pageFilter': pageFilter,
                'isCount': isCount,
            },
            errors: {
                401: `Invalid authorization data`,
            },
        });
    }

}
