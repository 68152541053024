/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UserDocumentDataPublicResponseDTO = {
    status: UserDocumentDataPublicResponseDTO.status;
};

export namespace UserDocumentDataPublicResponseDTO {

    export enum status {
        PENDING = 'pending',
        APPROVED = 'approved',
        REVIEW = 'review',
        DECLINED = 'declined',
    }


}
