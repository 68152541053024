import { regex } from "../constants/regex";
import { AnyData } from "../types/common";

export const debounce = (() => {
  let timer = 0;
  return function (callback: () => void, ms: number) {
    clearTimeout(timer);
    timer = window.setTimeout(callback, ms);
  };
})();

export const getFileLink = (url: string) => {
  if (!url) return "";
  return url;

  // This was used when files were stored on the server
  // if (
  //   ["http://", "https://", "ftp://", "blob:http://"].some((protocol) =>
  //     url.startsWith(protocol)
  //   )
  // )
  //   return url;

  // return `${config.apiUrl}/file/${url}`;
};

export const camelCaseToTitle = (camelCase: string) =>
  camelCase
    .replace(/([A-Z])/g, (match) => ` ${match}`)
    .replace(/^./, (match) => match.toUpperCase())
    .trim();

export const stringToCamelCase = (str: string) =>
  str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());

export const getPageCount = (count: number, limit: number) =>
  Math.ceil(count / limit);

export const uniqueArray = <T, K extends keyof T>(array: T[], key: K): T[] =>
  Array.from(new Map(array.map((item) => [item[key], item])).values());

export const scrollNearBottom = (element: Element, subtract = 0) => {
  const diff = Math.round(element.scrollHeight - element.scrollTop);
  return diff - subtract <= element.clientHeight;
};

export const scrollNearTop = (element: Element) => {
  return element.scrollTop <= 0;
};

export const addLineBreak = (text: string): string =>
  text.replace(regex.lineBreaks, "</br>");

export const fixMuiValue = (val: AnyData, isEmptyToNull = true): AnyData =>
  typeof val === "undefined" || (isEmptyToNull && !val) ? null : val;
