/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ChangeEmailConfirmDTO } from '../models/ChangeEmailConfirmDTO';
import type { ChangeEmailRequestDTO } from '../models/ChangeEmailRequestDTO';
import type { ChangeIsImportDTO } from '../models/ChangeIsImportDTO';
import type { ChangeReferralDataDTO } from '../models/ChangeReferralDataDTO';
import type { ChangeUserDataDTO } from '../models/ChangeUserDataDTO';
import type { DeleteFilesDTO } from '../models/DeleteFilesDTO';
import type { PageUserListResponseDTO } from '../models/PageUserListResponseDTO';
import type { ProfileMyResponseDTO } from '../models/ProfileMyResponseDTO';
import type { ProfileResponseDTO } from '../models/ProfileResponseDTO';
import type { UploadFileDTO } from '../models/UploadFileDTO';
import type { UploadFilesDTO } from '../models/UploadFilesDTO';
import type { UserListResponseDTO } from '../models/UserListResponseDTO';
import type { UserUploadImageDTO } from '../models/UserUploadImageDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class UserService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Add or update an image avatar, headShotImg, bodyShotImg
     * @returns string Path to the image. Use with the GET file request.
     * @throws ApiError
     */
    public uploadImage({
formData,
}: {
formData: UserUploadImageDTO,
}): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/user/upload-image',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                401: `Invalid authorization data`,
                404: `User not found`,
            },
        });
    }

    /**
     * My Profile
     * @returns ProfileMyResponseDTO My Profile
     * @throws ApiError
     */
    public getMyProfile(): CancelablePromise<ProfileMyResponseDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/user',
            errors: {
                401: `Invalid authorization data`,
                404: `User not found`,
            },
        });
    }

    /**
     * Update User Data
     * @returns any Updated User Data
     * @throws ApiError
     */
    public changeUserData({
requestBody,
}: {
requestBody: ChangeUserDataDTO,
}): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/user',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Invalid authorization data`,
                404: `User not found`,
            },
        });
    }

    /**
     * User delete
     * @returns any User deleted
     * @throws ApiError
     */
    public deleteUser(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/user',
            errors: {
                401: `Invalid authorization data`,
            },
        });
    }

    /**
     * Update the Start Status
     * Update the status by which a certain page will need to be shown until the user performs the action
     * @returns any Updated User Start Status
     * @throws ApiError
     */
    public changeStartStatus(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/user/change-start',
            errors: {
                401: `Invalid authorization data`,
                404: `User not found`,
            },
        });
    }

    /**
     * Update isImport status
     * @returns any Updated User isImport status
     * @throws ApiError
     */
    public changeIsImport({
requestBody,
}: {
requestBody: ChangeIsImportDTO,
}): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/user/change-is-import',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Invalid authorization data`,
                404: `User not found`,
            },
        });
    }

    /**
     * Update User Email Request
     * Creating verification code and sending it to new user email
     * @returns any Verification code has been sent to a new email
     * @throws ApiError
     */
    public changeEmailRequest({
requestBody,
}: {
requestBody: ChangeEmailRequestDTO,
}): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/user/change-email-request',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Request limit exceeded`,
                401: `Invalid authorization data`,
                404: `User not found`,
                409: `Email is busy`,
            },
        });
    }

    /**
     * Update User Email
     * Confirm email update by verification code from mail
     * @returns any User email updated
     * @throws ApiError
     */
    public changeEmailConfirm({
requestBody,
}: {
requestBody: ChangeEmailConfirmDTO,
}): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/user/change-email-confirm',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Code is invalid`,
                401: `Invalid authorization data`,
                404: `User not found`,
                409: `Email is busy`,
            },
        });
    }

    /**
     * Add photos
     * @returns string Photos added
     * @throws ApiError
     */
    public additionalPhotos({
formData,
}: {
formData: UploadFilesDTO,
}): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/user/upload-photos',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                401: `Invalid authorization data`,
                404: `User not found`,
            },
        });
    }

    /**
     * Delete photos
     * @returns any Photos Deleted
     * @throws ApiError
     */
    public deletePhotos({
requestBody,
}: {
requestBody: DeleteFilesDTO,
}): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/user/delete-photos',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Invalid authorization data`,
                404: `User not found`,
            },
        });
    }

    /**
     * Update User Referral Data
     * @returns any Updated Referral Data
     * @throws ApiError
     */
    public changeUserReferralData({
requestBody,
}: {
requestBody: ChangeReferralDataDTO,
}): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/user/referral',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Invalid authorization data`,
                409: `Code is busy`,
            },
        });
    }

    /**
     * Like User
     * @returns any 
     * @throws ApiError
     */
    public likeUser({
id,
}: {
/**
 * Id
 */
id: string,
}): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/user/{id}/like',
            path: {
                'id': id,
            },
            errors: {
                401: `Invalid authorization data`,
                404: `User not found`,
            },
        });
    }

    /**
     * Unlike User
     * @returns any 
     * @throws ApiError
     */
    public unlikeUser({
id,
}: {
/**
 * Id
 */
id: string,
}): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/user/{id}/unlike',
            path: {
                'id': id,
            },
            errors: {
                401: `Invalid authorization data`,
                404: `User not found`,
            },
        });
    }

    /**
     * Users List
     * @returns PageUserListResponseDTO List of users
     * @throws ApiError
     */
    public getUsersList({
search,
city,
cities,
genders,
userRole,
languages,
hairColors,
eyeColors,
dressSizes,
shoeSizes,
isOnlyLike,
isOnlyRecommended,
isFeatured,
isAmbassador,
isBoost,
boostCity,
startAge,
endAge,
heightFrom,
heightTo,
eventPreferences,
sort = 'createdAt',
sortDirection = -1,
createdAtFrom,
createdAtTo,
profileUpdatedAtFrom,
profileUpdatedAtTo,
page = 1,
limit = 10,
pageFilter,
isCount,
}: {
/**
 * Used to filter data by name
 */
search?: string,
/**
 * Send the _id
 */
city?: string,
/**
 * Send the _id
 */
cities?: Array<string>,
genders?: Array<'male' | 'female'>,
userRole?: 'client' | 'talent',
languages?: Array<string>,
hairColors?: Array<string>,
eyeColors?: Array<string>,
dressSizes?: Array<string>,
shoeSizes?: Array<string>,
isOnlyLike?: boolean,
isOnlyRecommended?: boolean,
isFeatured?: boolean,
isAmbassador?: boolean,
isBoost?: boolean,
/**
 * Send the _id
 */
boostCity?: string,
startAge?: number,
endAge?: number,
heightFrom?: number,
heightTo?: number,
eventPreferences?: 'entertainment' | 'matchmaking' | 'travels',
sort?: 'fullName' | 'earnedSum' | 'createdAt' | 'updatedAt' | 'profileUpdatedAt' | 'boostTopDate' | 'newIdVerification' | 'newMember',
sortDirection?: 1 | -1,
createdAtFrom?: string,
createdAtTo?: string,
profileUpdatedAtFrom?: string,
profileUpdatedAtTo?: string,
page?: number,
/**
 * If 0 is specified, we will return the data without a limit
 */
limit?: number,
/**
 * Used to filter the received data. We get data where the creation date is less than the specified date. If possible, use this to get the following data instead of the "page" parameter as it works faster. If you are sorting list by "updatedAt" date, you need to use "updatedAt" date for this param
 */
pageFilter?: string,
/**
 * If it actively returns the quantity.
 */
isCount?: boolean,
}): CancelablePromise<PageUserListResponseDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/user/list',
            query: {
                'search': search,
                'city': city,
                'cities': cities,
                'genders': genders,
                'userRole': userRole,
                'languages': languages,
                'hairColors': hairColors,
                'eyeColors': eyeColors,
                'dressSizes': dressSizes,
                'shoeSizes': shoeSizes,
                'isOnlyLike': isOnlyLike,
                'isOnlyRecommended': isOnlyRecommended,
                'isFeatured': isFeatured,
                'isAmbassador': isAmbassador,
                'isBoost': isBoost,
                'boostCity': boostCity,
                'startAge': startAge,
                'endAge': endAge,
                'heightFrom': heightFrom,
                'heightTo': heightTo,
                'eventPreferences': eventPreferences,
                'sort': sort,
                'sortDirection': sortDirection,
                'createdAtFrom': createdAtFrom,
                'createdAtTo': createdAtTo,
                'profileUpdatedAtFrom': profileUpdatedAtFrom,
                'profileUpdatedAtTo': profileUpdatedAtTo,
                'page': page,
                'limit': limit,
                'pageFilter': pageFilter,
                'isCount': isCount,
            },
            errors: {
                401: `Invalid authorization data`,
            },
        });
    }

    /**
     * Users Random List
     * @returns UserListResponseDTO List of users
     * @throws ApiError
     */
    public getUserRandomList({
city,
cities,
genders,
userRole,
languages,
hairColors,
eyeColors,
dressSizes,
shoeSizes,
isOnlyLike,
isFeatured,
isAmbassador,
isBoost,
boostCity,
startAge,
endAge,
heightFrom,
heightTo,
eventPreferences,
sortDirection = -1,
createdAtFrom,
createdAtTo,
profileUpdatedAtFrom,
profileUpdatedAtTo,
limit = 10,
}: {
/**
 * Send the _id
 */
city?: string,
/**
 * Send the _id
 */
cities?: Array<string>,
genders?: Array<'male' | 'female'>,
userRole?: 'client' | 'talent',
languages?: Array<string>,
hairColors?: Array<string>,
eyeColors?: Array<string>,
dressSizes?: Array<string>,
shoeSizes?: Array<string>,
isOnlyLike?: boolean,
isFeatured?: boolean,
isAmbassador?: boolean,
isBoost?: boolean,
/**
 * Send the _id
 */
boostCity?: string,
startAge?: number,
endAge?: number,
heightFrom?: number,
heightTo?: number,
eventPreferences?: 'entertainment' | 'matchmaking' | 'travels',
sortDirection?: 1 | -1,
createdAtFrom?: string,
createdAtTo?: string,
profileUpdatedAtFrom?: string,
profileUpdatedAtTo?: string,
limit?: number,
}): CancelablePromise<Array<UserListResponseDTO>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/user/random/list',
            query: {
                'city': city,
                'cities': cities,
                'genders': genders,
                'userRole': userRole,
                'languages': languages,
                'hairColors': hairColors,
                'eyeColors': eyeColors,
                'dressSizes': dressSizes,
                'shoeSizes': shoeSizes,
                'isOnlyLike': isOnlyLike,
                'isFeatured': isFeatured,
                'isAmbassador': isAmbassador,
                'isBoost': isBoost,
                'boostCity': boostCity,
                'startAge': startAge,
                'endAge': endAge,
                'heightFrom': heightFrom,
                'heightTo': heightTo,
                'eventPreferences': eventPreferences,
                'sortDirection': sortDirection,
                'createdAtFrom': createdAtFrom,
                'createdAtTo': createdAtTo,
                'profileUpdatedAtFrom': profileUpdatedAtFrom,
                'profileUpdatedAtTo': profileUpdatedAtTo,
                'limit': limit,
            },
            errors: {
                401: `Invalid authorization data`,
            },
        });
    }

    /**
     * User Count
     * @returns number User Count
     * @throws ApiError
     */
    public getUserCount({
search,
city,
cities,
genders,
userRole,
languages,
hairColors,
eyeColors,
dressSizes,
shoeSizes,
isOnlyLike,
isOnlyRecommended,
isFeatured,
isAmbassador,
isBoost,
boostCity,
startAge,
endAge,
heightFrom,
heightTo,
eventPreferences,
sort = 'createdAt',
sortDirection = -1,
createdAtFrom,
createdAtTo,
profileUpdatedAtFrom,
profileUpdatedAtTo,
}: {
/**
 * Used to filter data by name
 */
search?: string,
/**
 * Send the _id
 */
city?: string,
/**
 * Send the _id
 */
cities?: Array<string>,
genders?: Array<'male' | 'female'>,
userRole?: 'client' | 'talent',
languages?: Array<string>,
hairColors?: Array<string>,
eyeColors?: Array<string>,
dressSizes?: Array<string>,
shoeSizes?: Array<string>,
isOnlyLike?: boolean,
isOnlyRecommended?: boolean,
isFeatured?: boolean,
isAmbassador?: boolean,
isBoost?: boolean,
/**
 * Send the _id
 */
boostCity?: string,
startAge?: number,
endAge?: number,
heightFrom?: number,
heightTo?: number,
eventPreferences?: 'entertainment' | 'matchmaking' | 'travels',
sort?: 'fullName' | 'earnedSum' | 'createdAt' | 'updatedAt' | 'profileUpdatedAt' | 'boostTopDate' | 'newIdVerification' | 'newMember',
sortDirection?: 1 | -1,
createdAtFrom?: string,
createdAtTo?: string,
profileUpdatedAtFrom?: string,
profileUpdatedAtTo?: string,
}): CancelablePromise<number> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/user/count',
            query: {
                'search': search,
                'city': city,
                'cities': cities,
                'genders': genders,
                'userRole': userRole,
                'languages': languages,
                'hairColors': hairColors,
                'eyeColors': eyeColors,
                'dressSizes': dressSizes,
                'shoeSizes': shoeSizes,
                'isOnlyLike': isOnlyLike,
                'isOnlyRecommended': isOnlyRecommended,
                'isFeatured': isFeatured,
                'isAmbassador': isAmbassador,
                'isBoost': isBoost,
                'boostCity': boostCity,
                'startAge': startAge,
                'endAge': endAge,
                'heightFrom': heightFrom,
                'heightTo': heightTo,
                'eventPreferences': eventPreferences,
                'sort': sort,
                'sortDirection': sortDirection,
                'createdAtFrom': createdAtFrom,
                'createdAtTo': createdAtTo,
                'profileUpdatedAtFrom': profileUpdatedAtFrom,
                'profileUpdatedAtTo': profileUpdatedAtTo,
            },
            errors: {
                401: `Invalid authorization data`,
            },
        });
    }

    /**
     * Add or update video
     * @returns string Path to the video. Use with the GET file request.
     * @throws ApiError
     */
    public uploadVideo({
formData,
}: {
formData: UploadFileDTO,
}): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/user/upload-video',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                401: `Invalid authorization data`,
                403: `You must be a talent`,
                404: `User not found`,
            },
        });
    }

    /**
     * User video delete
     * @returns any User video deleted
     * @throws ApiError
     */
    public deleteUserVideo(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/user/delete-video',
            errors: {
                401: `Invalid authorization data`,
                403: `You must be a talent`,
                404: `User not found`,
            },
        });
    }

    /**
     * User Profile
     * @returns ProfileResponseDTO User Profile
     * @throws ApiError
     */
    public getUserProfile({
id,
}: {
/**
 * Id
 */
id: string,
}): CancelablePromise<ProfileResponseDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/user/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Invalid authorization data`,
                404: `User not found`,
            },
        });
    }

}
