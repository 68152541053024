import appClient from "src/api/appClient";
import { getError } from "src/common/utils/error";
import {
  DownloadScvUserListHook,
  GetUserCountHook,
  GetUserListHook,
} from "../types/hook";
import { useEffect, useState } from "react";
import {
  AdminAddRemoveRecommendedUserDTO,
  AdminChangeUserDTO,
  AdminProfileResponseDTO,
  AdminUserListResponseDTO,
  AdminUserVerificationDTO,
  ChangeAdminPasswordDTO,
  ChangeAdminRightsDTO,
  ChangeUserAdditionalFeaturesDTO,
  ChangeUserAmbassadorDTO,
  ChangeUserBoostTopDTO,
  ChangeUserBoostTopResponseDTO,
  ChangeUserDataDTO,
  CreateAdminUserDTO,
  UserUploadImageDTO,
} from "src/api/openapi";
import SnackbarContext from "src/components/store/contexts/snackbar-context";
import { useNavigate } from "react-router-dom";
import download from "downloadjs";
import { camelCaseToTitle, getPageCount, uniqueArray } from "../utils/fn";
import CityContext from "src/components/store/contexts/city-context";
import { UserCountRequest, UserListRequest } from "../types/requestApi";
import {
  defaultPageLimit,
  defaultPageSelectLimit,
  maxSelectItems,
} from "../constants/common";
import { isConcatNext, setPageLimitParams } from "../utils/request";
import { SelectItem } from "../types/ui";
import { getUserFullName } from "../utils/user";
import { tokenHook } from "./tokenHook";
import { allItem } from "../constants/select";
import { ResponseResult } from "../types/common";

export const getUserListHook = ({
  defaultParams,
  isCount = false,
  isCitySelect = true,
  isGetData = true,
  isConcat = false,
  cityName = "city",
}: GetUserListHook) => {
  const { setData: setSnackbar } = SnackbarContext.useContext();
  const {
    data: { value: citySelect },
  } = CityContext.useContext();
  const [params, setParams] = useState<UserListRequest>({
    page: 1,
    limit: defaultPageLimit,
    search: "",
    [cityName]: isCitySelect ? citySelect.value : undefined,
    ...defaultParams,
    isCount,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isAllResult, setIsAllResult] = useState<boolean>(false);
  const [users, setUsers] = useState<AdminUserListResponseDTO[]>([]);
  const [count, setCount] = useState<number>(0);

  const getData = () => {
    setIsLoading(true);
    if (!isConcatNext(params)) setIsAllResult(false);
    appClient.adminUser
      .getAdminUserList(params)
      .then((data) => {
        if (data.results.length === 0 || data.results.length < params.limit)
          setIsAllResult(true);

        if (isConcat && isConcatNext(params)) {
          setUsers((prev) => uniqueArray([...prev, ...data.results], "_id"));
        } else {
          setUsers(data.results);
        }

        if (data.resultCount || data.resultCount === 0) {
          setCount(data.resultCount);
        }
      })
      .catch((error) => {
        getError(error, setSnackbar);
      })
      .finally(() => setIsLoading(false));
  };

  const handleParams = (data: Partial<UserListRequest>) =>
    setParams((prev) => setPageLimitParams({ prev, data, isCount }));

  useEffect(() => {
    if (isCitySelect) handleParams({ [cityName]: citySelect.value });
  }, [citySelect.value]);

  useEffect(() => {
    if (!isLoading && isGetData) getData();
  }, [params, isGetData]);

  return {
    isLoading,
    count,
    users,
    setUsers,
    setCount,
    handleParams,
    params,
    isAllResult,
  };
};

export const getUserCountHook = ({
  defaultParams,
  isCitySelect = true,
  isGetData = true,
}: GetUserCountHook) => {
  const { setData: setSnackbar } = SnackbarContext.useContext();
  const {
    data: { value: citySelect },
  } = CityContext.useContext();
  const [params, setParams] = useState<UserCountRequest>({
    city: isCitySelect ? citySelect.value : undefined,
    ...defaultParams,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [count, setCount] = useState<number>(0);

  const getData = () => {
    setIsLoading(true);
    appClient.adminUser
      .getAdminUserCount(params)
      .then((data) => {
        setCount(data);
      })
      .catch((error) => {
        getError(error, setSnackbar);
      })
      .finally(() => setIsLoading(false));
  };

  const handleParams = (data: Partial<UserCountRequest>) =>
    setParams((prev) => ({ ...prev, ...data }));

  useEffect(() => {
    if (isCitySelect) handleParams({ city: citySelect.value });
  }, [citySelect.value]);

  useEffect(() => {
    if (!isLoading && isGetData) getData();
  }, [params, isGetData]);

  return {
    isLoading,
    count,
    setCount,
    handleParams,
    params,
  };
};

export const getUserHook = (id: string, returnLink: string) => {
  const navigate = useNavigate();
  const { setData: setSnackbar } = SnackbarContext.useContext();
  const [user, setUser] = useState<AdminProfileResponseDTO>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getData = async () => {
    if (!id) return;
    setIsLoading(true);
    await appClient.adminUser
      .getAdminUser({ id })
      .then((data) => {
        setUser(data);
      })
      .catch((error) => {
        navigate(returnLink);
        getError(error, setSnackbar);
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (!isLoading) getData();
  }, [id]);

  return {
    isLoading,
    user,
    setUser,
    getData,
  };
};

export const userUpdateHook = () => {
  const { setData: setSnackbar } = SnackbarContext.useContext();
  const [newAvatarPath, setNewAvatarPath] = useState("");
  const [changeEmailRequestSended, setChangeEmailRequestSended] =
    useState(false);
  const [isEmailChanged, setIsEmailChanged] = useState(false);

  const updateUser = async (id: string, data: AdminChangeUserDTO) => {
    await appClient.adminUser
      .changeUserProfile({
        id,
        requestBody: data,
      })
      .then(() => {
        setSnackbar({
          open: true,
          message: "User successfully updated",
          type: "success",
        });
      })
      .catch((error) => {
        getError(error, setSnackbar);
      });
  };

  const updateUserAmbassador = async (
    id: string,
    data: ChangeUserAmbassadorDTO
  ) => {
    await appClient.adminUser
      .changeUserAmbassador({
        id,
        requestBody: data,
      })
      .then(() => {
        setSnackbar({
          open: true,
          message: "User Ambassador successfully updated",
          type: "success",
        });
      })
      .catch((error) => {
        getError(error, setSnackbar);
      });
  };

  const updateUserStatus = async (requestBody: AdminUserVerificationDTO) => {
    await appClient.adminUser
      .updateUserStatus({
        requestBody,
      })
      .then(() => {
        setSnackbar({
          open: true,
          message: "User status successfully updated",
          type: "success",
        });
      })
      .catch((error) => {
        getError(error, setSnackbar);
      });
  };

  const updateAdditionalFeatures = async (
    id: string,
    data: ChangeUserAdditionalFeaturesDTO
  ) => {
    await appClient.adminUser
      .changeUserAdditionalFeatures({
        id,
        requestBody: data,
      })
      .then(() => {
        setSnackbar({
          open: true,
          message: "User additional features successfully updated",
          type: "success",
        });
      })
      .catch((error) => {
        getError(error, setSnackbar);
      });
  };

  const addUserTop = (
    id: string,
    data: ChangeUserBoostTopDTO
  ): Promise<ResponseResult<ChangeUserBoostTopResponseDTO>> => {
    return appClient.adminUser
      .changeUserBoostTop({
        id,
        requestBody: data,
      })
      .then((data) => {
        setSnackbar({
          open: true,
          message: "User successfully busted",
          type: "success",
        });
        return { isError: false, data };
      })
      .catch((error) => {
        getError(error, setSnackbar);
        return { isError: true, error };
      });
  };

  const changePasswordUser = async (data: ChangeAdminPasswordDTO) => {
    await appClient.adminUser
      .changeAdminPassword({
        requestBody: data,
      })
      .then(() => {
        setSnackbar({
          open: true,
          message: "Password successfully updated",
          type: "success",
        });
      })
      .catch((error) => {
        getError(error, setSnackbar);
      });
  };

  const updateMyProfile = async (data: ChangeUserDataDTO) => {
    await appClient.user
      .changeUserData({
        requestBody: data,
      })
      .then(() => {
        setSnackbar({
          open: true,
          message: "User successfully updated",
          type: "success",
        });
      })
      .catch((error) => {
        getError(error, setSnackbar);
      });
  };

  const deleteUser = async () => {
    await appClient.user
      .deleteUser()
      .then(() => {
        setSnackbar({
          open: true,
          message: "User successfully deleted",
          type: "success",
        });
      })
      .catch((error) => {
        getError(error, setSnackbar);
      });
  };

  const uploadAvatar = async (file: Blob) => {
    await appClient.user
      .uploadImage({
        formData: {
          file,
          name: UserUploadImageDTO.name.AVATAR,
        },
      })
      .then((res) => {
        setNewAvatarPath(res);
        setSnackbar({
          open: true,
          message: "Avatar successfully uploaded",
          type: "success",
        });
      })
      .catch((error) => {
        getError(error, setSnackbar);
      });
  };

  const changeEmailRequest = async (email: string) => {
    await appClient.user
      .changeEmailRequest({
        requestBody: {
          email,
        },
      })
      .then(() => {
        setChangeEmailRequestSended(true);
        setSnackbar({
          open: true,
          message: "Confirm email sended",
          type: "success",
        });
      })
      .catch((error) => {
        getError(error, setSnackbar);
      });
  };

  const changeEmailConfirm = async (email: string, code: string) => {
    await appClient.user
      .changeEmailConfirm({
        requestBody: {
          email,
          code,
        },
      })
      .then(() => {
        setIsEmailChanged(true);
        setSnackbar({
          open: true,
          message: "New email confirmed",
          type: "success",
        });
      })
      .catch((error) => {
        getError(error, setSnackbar);
      });
  };

  const addRemoveRecommended = (
    id: string,
    data: AdminAddRemoveRecommendedUserDTO
  ): Promise<ResponseResult<void>> => {
    return appClient.adminUser
      .addRemoveRecommended({
        id,
        requestBody: data,
      })
      .then((data) => {
        setSnackbar({
          open: true,
          message: "Successfully updated recommended",
          type: "success",
        });
        return { isError: false, data };
      })
      .catch((error) => {
        getError(error, setSnackbar);
        return { isError: true, error };
      });
  };

  return {
    updateUser,
    updateUserStatus,
    updateAdditionalFeatures,
    addUserTop,
    changePasswordUser,
    updateMyProfile,
    deleteUser,
    uploadAvatar,
    newAvatarPath,
    changeEmailRequest,
    changeEmailRequestSended,
    setChangeEmailRequestSended,
    changeEmailConfirm,
    isEmailChanged,
    addRemoveRecommended,
    updateUserAmbassador,
  };
};

export const downloadCsvUserHook = () => {
  const { setData: setSnackbar } = SnackbarContext.useContext();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const downloadCsvUser = ({ params }: DownloadScvUserListHook) => {
    if (isLoading) return;
    setIsLoading(true);
    return appClient.adminUser
      .exportUserCsv(params)
      .then((data) => {
        download(
          data,
          `export${
            params.userRole ? camelCaseToTitle(params.userRole) : "user"
          }s.csv`,
          "text/plain"
        );
      })
      .catch((error) => {
        getError(error, setSnackbar);
      })
      .finally(() => setIsLoading(false));
  };

  return {
    downloadCsvUser,
    isLoading,
  };
};

export const userDeleteHook = () => {
  const { setData: setSnackbar } = SnackbarContext.useContext();

  const deleteUser = async (id: string) => {
    return appClient.adminUser
      .adminDeleteUser({
        id,
      })
      .then((data) => {
        setSnackbar({
          open: true,
          message: "User successfully deleted",
          type: "success",
        });
        return { isError: false, data };
      })
      .catch((error) => {
        getError(error, setSnackbar);
        return { isError: true, error };
      });
  };

  return {
    deleteUser,
  };
};

export const logoutHook = () => {
  const navigate = useNavigate();
  const { removeTokens } = tokenHook();

  const logout = async () => {
    removeTokens();
    navigate("/login");
  };

  return {
    logout,
  };
};

export const getUsersSelectListHook = ({
  defaultParams,
  isAllLabel = false,
  valueItems = [],
  uniqueKey = "value",
}: GetUserListHook) => {
  const { setData: setSnackbar } = SnackbarContext.useContext();
  const [params, setParams] = useState<UserListRequest>({
    page: 1,
    limit: defaultPageSelectLimit,
    search: "",
    isCount: false,
    ...defaultParams,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isGetData, setIsGetData] = useState<boolean>(false);
  const [users, setUsers] = useState<SelectItem<string>[]>([]);
  const [count, setCount] = useState<number>(0);
  const [pageCount, setPageCount] = useState<number>(1);

  const setSelects = (newData: SelectItem<string>[]) => {
    const spliceStart =
      users.length > maxSelectItems ? users.length - maxSelectItems : 0;

    const newCityItems = uniqueArray(
      [allItem, ...users.slice(spliceStart), ...valueItems, ...newData],
      uniqueKey
    );

    setUsers(newCityItems);
  };

  const getData = (newParams?: Partial<UserListRequest>) => {
    setIsLoading(true);

    appClient.adminUser
      .getAdminUserList({ ...params, ...newParams })
      .then((data) => {
        if (!isGetData) setIsGetData(true);

        setSelects(
          data.results.map((item) => ({
            value: item._id,
            label: getUserFullName(item),
            labelTwo: item.role,
          }))
        );

        if (typeof data.resultCount === "number") {
          setCount(data.resultCount);
          if (data.limit > 0)
            setPageCount(getPageCount(data.resultCount, data.limit));
        }
      })
      .catch((error) => {
        getError(error, setSnackbar);
      })
      .finally(() => setIsLoading(false));
  };

  const getSearch = (search: string) => {
    if (!search && isGetData) return;
    if (search && users.length === count) return;

    getData({ isCount: !isGetData, search: search });
  };

  const getNextPage = () => {
    if (params.page >= pageCount) return;
    const newPage = params.page + 1;
    setParams({ ...params, page: newPage });

    getData({ page: newPage });
  };

  useEffect(() => {
    setSelects([]);
  }, [JSON.stringify(valueItems)]);

  return {
    isGetData,
    isLoading,
    count,
    usersItems: isAllLabel ? users : users.filter((item) => item.value),
    getSearch,
    getNextPage,
    setSelects,
  };
};

export const adminUserHooks = () => {
  const { setData: setSnackbar } = SnackbarContext.useContext();

  const createAdminUser = (data: CreateAdminUserDTO) => {
    return appClient.adminUser
      .createAdminUser({
        requestBody: data,
      })
      .then((data) => {
        setSnackbar({
          open: true,
          message: "Admin successfully created",
          type: "success",
        });
        return { isError: false, data };
      })
      .catch((error) => {
        getError(error, setSnackbar);
        return { isError: true, error };
      });
  };

  const updateAdminUserRights = (id: string, data: ChangeAdminRightsDTO) => {
    return appClient.adminUser
      .changeAdminRights({
        id,
        requestBody: data,
      })
      .then((data) => {
        setSnackbar({
          open: true,
          message: "Admin rights successfully updated",
          type: "success",
        });
        return { isError: false, data };
      })
      .catch((error) => {
        getError(error, setSnackbar);
        return { isError: true, error };
      });
  };
  return { updateAdminUserRights, createAdminUser };
};
