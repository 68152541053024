/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { AxiosHttpRequest } from './core/AxiosHttpRequest';

import { AdminUserService } from './services/AdminUserService';
import { AuthService } from './services/AuthService';
import { BlockService } from './services/BlockService';
import { BookingService } from './services/BookingService';
import { BookingChatService } from './services/BookingChatService';
import { BookingInstructionService } from './services/BookingInstructionService';
import { CharityService } from './services/CharityService';
import { CityService } from './services/CityService';
import { CountryService } from './services/CountryService';
import { DiningStyleService } from './services/DiningStyleService';
import { DressCodeService } from './services/DressCodeService';
import { EmailService } from './services/EmailService';
import { EyeColorService } from './services/EyeColorService';
import { HairColorService } from './services/HairColorService';
import { InterestService } from './services/InterestService';
import { KitchenService } from './services/KitchenService';
import { LanguageService } from './services/LanguageService';
import { LikeService } from './services/LikeService';
import { LuxuryServiceService } from './services/LuxuryServiceService';
import { LuxuryServiceRequestService } from './services/LuxuryServiceRequestService';
import { NotificationService } from './services/NotificationService';
import { PaymentService } from './services/PaymentService';
import { PaymentMethodService } from './services/PaymentMethodService';
import { PaymentSubscriptionService } from './services/PaymentSubscriptionService';
import { PlatformSettingsService } from './services/PlatformSettingsService';
import { PushNotificationsService } from './services/PushNotificationsService';
import { ReferralService } from './services/ReferralService';
import { ReviewService } from './services/ReviewService';
import { SupportService } from './services/SupportService';
import { SystemMessagesService } from './services/SystemMessagesService';
import { TransactionService } from './services/TransactionService';
import { UserService } from './services/UserService';
import { UserAccountService } from './services/UserAccountService';
import { UserDocumentService } from './services/UserDocumentService';
import { VenueExperienceService } from './services/VenueExperienceService';
import { VenueTypeDataService } from './services/VenueTypeDataService';
import { WalletService } from './services/WalletService';

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class AppClient {

    public readonly adminUser: AdminUserService;
    public readonly auth: AuthService;
    public readonly block: BlockService;
    public readonly booking: BookingService;
    public readonly bookingChat: BookingChatService;
    public readonly bookingInstruction: BookingInstructionService;
    public readonly charity: CharityService;
    public readonly city: CityService;
    public readonly country: CountryService;
    public readonly diningStyle: DiningStyleService;
    public readonly dressCode: DressCodeService;
    public readonly email: EmailService;
    public readonly eyeColor: EyeColorService;
    public readonly hairColor: HairColorService;
    public readonly interest: InterestService;
    public readonly kitchen: KitchenService;
    public readonly language: LanguageService;
    public readonly like: LikeService;
    public readonly luxuryService: LuxuryServiceService;
    public readonly luxuryServiceRequest: LuxuryServiceRequestService;
    public readonly notification: NotificationService;
    public readonly payment: PaymentService;
    public readonly paymentMethod: PaymentMethodService;
    public readonly paymentSubscription: PaymentSubscriptionService;
    public readonly platformSettings: PlatformSettingsService;
    public readonly pushNotifications: PushNotificationsService;
    public readonly referral: ReferralService;
    public readonly review: ReviewService;
    public readonly support: SupportService;
    public readonly systemMessages: SystemMessagesService;
    public readonly transaction: TransactionService;
    public readonly user: UserService;
    public readonly userAccount: UserAccountService;
    public readonly userDocument: UserDocumentService;
    public readonly venueExperience: VenueExperienceService;
    public readonly venueTypeData: VenueTypeDataService;
    public readonly wallet: WalletService;

    public readonly request: BaseHttpRequest;

    constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = AxiosHttpRequest) {
        this.request = new HttpRequest({
            BASE: config?.BASE ?? 'https://dev.theselekt.com/api',
            VERSION: config?.VERSION ?? '1.0',
            WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
            CREDENTIALS: config?.CREDENTIALS ?? 'include',
            TOKEN: config?.TOKEN,
            USERNAME: config?.USERNAME,
            PASSWORD: config?.PASSWORD,
            HEADERS: config?.HEADERS,
            ENCODE_PATH: config?.ENCODE_PATH,
        });

        this.adminUser = new AdminUserService(this.request);
        this.auth = new AuthService(this.request);
        this.block = new BlockService(this.request);
        this.booking = new BookingService(this.request);
        this.bookingChat = new BookingChatService(this.request);
        this.bookingInstruction = new BookingInstructionService(this.request);
        this.charity = new CharityService(this.request);
        this.city = new CityService(this.request);
        this.country = new CountryService(this.request);
        this.diningStyle = new DiningStyleService(this.request);
        this.dressCode = new DressCodeService(this.request);
        this.email = new EmailService(this.request);
        this.eyeColor = new EyeColorService(this.request);
        this.hairColor = new HairColorService(this.request);
        this.interest = new InterestService(this.request);
        this.kitchen = new KitchenService(this.request);
        this.language = new LanguageService(this.request);
        this.like = new LikeService(this.request);
        this.luxuryService = new LuxuryServiceService(this.request);
        this.luxuryServiceRequest = new LuxuryServiceRequestService(this.request);
        this.notification = new NotificationService(this.request);
        this.payment = new PaymentService(this.request);
        this.paymentMethod = new PaymentMethodService(this.request);
        this.paymentSubscription = new PaymentSubscriptionService(this.request);
        this.platformSettings = new PlatformSettingsService(this.request);
        this.pushNotifications = new PushNotificationsService(this.request);
        this.referral = new ReferralService(this.request);
        this.review = new ReviewService(this.request);
        this.support = new SupportService(this.request);
        this.systemMessages = new SystemMessagesService(this.request);
        this.transaction = new TransactionService(this.request);
        this.user = new UserService(this.request);
        this.userAccount = new UserAccountService(this.request);
        this.userDocument = new UserDocumentService(this.request);
        this.venueExperience = new VenueExperienceService(this.request);
        this.venueTypeData = new VenueTypeDataService(this.request);
        this.wallet = new WalletService(this.request);
    }
}
